import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

export default function CompleteSale(props) {
  const { subtotal, cashReceived, change, setChange, handleCompleteSave } =
    props;
  useEffect(() => {
    let ch = parseFloat(cashReceived) - parseFloat(subtotal);
    setChange(ch.toFixed(2));
  });
  return (
    <Card className="py-5 px-2">
      <CardBody>
        <Row>
          <Col lg={12} className="text-center">
            <h2>
              Give ${change}
              <br /> Change
            </h2>
          </Col>
          <Col lg={12}>
            <Button className="w-100" onClick={handleCompleteSave}>
              <h4 className="m-0 p-0">Complete Sale</h4>
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
