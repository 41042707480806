import React from "react";
import { useEffect } from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import axiosClient from "../../axios-client";
import OpenRegistor from "./Components/OpenRegistor";
import CloseRegistor from "./Components/CloseRegistor";
import { useState } from "react";
import LoadingBar from "react-top-loading-bar";

export default function Registor() {
  const [registorId, setRegistorId] = useState(null);
  const [content, setContent] = useState({});
  const [submitStatus, setSubmitStatus] = useState(false);
  const [progress, setProgress] = useState(0);
  const [registorStatus, setRegistorStatus] = useState(null);
  const [registorData, setRegistorData] = useState({});
  useEffect(() => {
    setProgress(25);
    axiosClient
      .get("/check-registor")
      .then(({ data }) => {
        if (data.registor === 0) {
          setRegistorStatus(0);
          setRegistorId(data.id);
        } else if (data.registor === 1) {
          setRegistorStatus(1);
          setRegistorId(data.id);
          setRegistorData(data.registor_data);
        } else {
          setRegistorStatus(2);
          setRegistorId(data.id);
          setRegistorData(data.registor_data);
        }
        setProgress(100);
      })
      .catch((data) => {
        setProgress(100);
      });
  }, [submitStatus]);
  return (
    <div className="content">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <CardHeader>
          {/* <CardTitle tag="h4">Open Registor</CardTitle> */}
          <CardTitle tag="h4">
            {registorStatus !== null
              ? registorStatus === 0
                ? "Open Registor"
                : "Close Registor"
              : ""}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {registorStatus !== null ? (
            registorStatus === 0 ? (
              <OpenRegistor
                content={content}
                setContent={setContent}
                setRegistorStatus={setRegistorStatus}
                setSubmitStatus={setSubmitStatus}
              />
            ) : (
              <CloseRegistor
                registorId={registorId}
                setSubmitStatus={setSubmitStatus}
                registorData={registorData}
                setRegistorStatus={setRegistorStatus}
              />
            )
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </div>
  );
}
