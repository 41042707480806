import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import closed from "../../../assets/images/closed.svg";
import LoadingBar from "react-top-loading-bar";
import { useStateContext } from "../../../contexts/ContextProvider";
import axiosClient from "../../../axios-client";
import Alert from "../../Alert";

export default function OpenRegistor(props) {
  const { setRegistorStatus } = props;
  const { setNotification } = useStateContext();
  const [progress, setProgress] = useState(0);
  const [content, setContent] = useState({
    open_float_cash: "",
  });
  const [errors, setErrors] = useState(null);
  const onSubmit = (ev) => {
    ev.preventDefault();
    setProgress(25);
    setErrors(null);
    const payload = content;
    payload.status = "open";
    axiosClient
      .post("/registors", payload)
      .then((data) => {
        setRegistorStatus(1);
        setProgress(100);
        setNotification(200, "Registor Open SuccessFully");
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setProgress(100);
          setErrors(response.data.errors);
          setNotification(400, "Some Error Occurs");
        }
      });
  };
  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card className="card-user ct-min-card-closed-height">
        <CardHeader>
          <CardTitle tag="h5" className="text-center pt-4 ct-fw-bold">
            <img src={closed} alt="closed_logo" />
            <br />
            Register closed
          </CardTitle>
        </CardHeader>
        <CardBody className="mx-auto">
          {errors && (
            <div className="px-3">
              <Alert errors={errors} />
            </div>
          )}
          <p>Set an opening float to open the register and make a sale.</p>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <label className="ct-fw-bold">Opening Float</label>
              <Input
                placeholder="Enter Opening Float"
                type="number"
                value={content.open_float_cash}
                onChange={(ev) =>
                  setContent({ ...content, open_float_cash: ev.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <label className="ct-fw-bold">Notes(optional)</label>
              <textarea
                className="form-control"
                placeholder="Enter Opening Float"
                onChange={(ev) =>
                  setContent({ ...content, open_note: ev.target.value })
                }
              >
                {content.open_note}
              </textarea>
            </FormGroup>
            <FormGroup>
              <Button className="btn-round w-100" color="primary" type="submit">
                Open Register
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
