import React from "react";
import { Col, Input, Row, Table } from "reactstrap";
import moment from "moment";

export default function PaymentSummary(props) {
  const { pSummary, setPSumary, registorData } = props;

  const handleAmount = (ev) => {
    if (ev.target.value) {
      const dif = parseFloat(ev.target.value) - pSummary.expected_cash;
      setPSumary({
        ...pSummary,
        close_float_cash: ev.target.value,
        counted_cash: ev.target.value,
        diiference: dif,
      });
    } else {
      const dif = parseFloat(0) - pSummary.expected_cash;
      setPSumary({
        ...pSummary,
        close_float_cash: 0,
        counted_cash: 0,
        diiference: dif,
      });
    }
  };
  return (
    <div>
      <h6
        className="p-0 m-0 py-3 mt-4 px-2"
        style={{ background: "rgb(242, 242, 242)" }}
      >
        Payments Summary
      </h6>
      <Row>
        <Col lg={3}>
          <p className="pt-2">
            Balance your register by entering the amount counted from the till
            and other payment terminals into the empty fields here.
          </p>
        </Col>
        <Col lg={9}>
          <Table className="table-hover" responsive>
            <thead className="text-primary">
              <tr>
                <th>Payment Types</th>
                <th>Expected($)</th>
                <th>Counted($)</th>
                <th>Differences($)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h6 className="p-0 m-0">Cash</h6>
                  <small>
                    View Cash Payments,
                    <br /> Floats and Movements
                  </small>
                </td>
                <td>{pSummary.expected_cash}</td>
                <td>
                  <Input
                    type="number"
                    placeholder="Amount"
                    value={pSummary.close_float_cash}
                    onChange={handleAmount}
                  />
                </td>
                <td>
                  {pSummary.diiference !== ""
                    ? pSummary.diiference !== NaN
                      ? pSummary.diiference
                      : "-"
                    : "-"}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Table>
                    <tbody>
                      <td>
                        <small>Cash Movements</small>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>User</th>
                            <th>Time</th>
                            <th>Amount($)</th>
                            <th>Reason</th>
                          </thead>
                          <tbody>
                            <td>
                              {moment(registorData.created_at).format("LTS")}
                            </td>
                            <td>{pSummary && pSummary.user_details && pSummary.user_details.name}</td>
                            <td>{pSummary.open_float_cash}</td>
                            <td>{pSummary.open_note}</td>
                          </tbody>
                        </Table>
                      </td>
                    </tbody>
                  </Table>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Cash Payments Received</td>
                <td>{pSummary.payment_received}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th>Totals</th>
                <th>{pSummary.expected_cash}</th>
                <th>{pSummary.counted_cash}</th>
                <th>{pSummary.diiference}</th>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
