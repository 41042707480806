import React, { useEffect, useState } from 'react'

export default function Pagination(props) {
    const {metaData, handlePagnate} = props;
    const [links, setLinks] = useState([]);
    useEffect(()=>{
        
        if( metaData.plinks ){
            setLinks(metaData.plinks);
        }
        
    }, [metaData.plinks, links])
  return (
    <>
        <div className="demo-inline-spacing d-flex justify-content-between">
            <div style={{paddingLeft: '10px'}}>
                <h6>Total Results: <span style={{fontWeight: '400'}}>{metaData.total}</span></h6>
            </div>
            <nav aria-label="Page navigation">
                <ul className="pagination">
                <li className="page-item prev">
                    <span className="page-link" style={{cursor: 'pointer'}} onClick={()=>handlePagnate('first')}
                    >First</span>
                </li>
                <li className="page-item prev">
                    <span className="page-link" style={{cursor: 'pointer'}} onClick={()=>handlePagnate('prev')}
                    ><i className="nc-icon nc-minimal-left"></i></span>
                </li>
                {
                    links.map((value, index)=>{
                        if(value['label'] !== "&laquo; Previous" && value['label'] !== "Next &raquo;"){
                            return (
                                <li className={value['active']===true ? 'page-item active':'page-item'} style={{cursor: 'pointer'}} onClick={()=>handlePagnate('single', value['url'])} key={index}>
                                    <span className="page-link">{value['label']}</span>
                                </li>
                            );
                        }
                        return ('')
                    })
                }
                <li className="page-item next">
                    <span className="page-link" style={{cursor: 'pointer'}} onClick={()=>handlePagnate('next')}
                    ><i className="nc-icon nc-minimal-right"></i></span>
                </li>
                <li className="page-item next">
                    <span className="page-link" style={{cursor: 'pointer'}} onClick={()=>handlePagnate('last')}
                    >Last</span>
                </li>
                </ul>
            </nav>
            <div>
                {  metaData.from && 
                    <h6>{metaData.from} to {metaData.to}</h6>
                }
            </div>
        </div>
    </>
  )
}
