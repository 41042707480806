import React from "react";
import { useEffect } from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { useStateContext } from "../../../contexts/ContextProvider";

export default function AmountPay2(props) {
  const {
    subtotal,
    cashReceived,
    setCashReceived,
    views,
    setViews,
    paymentType,
    setPaymentType,
  } = props;
  const { setNotification } = useStateContext();
  useEffect(() => {
    setCashReceived(subtotal);
  }, [subtotal]);

  const handleCashReceived = (ev) => {
    if (ev.target.value) {
      setCashReceived(ev.target.value);
    } else {
      setCashReceived(0);
    }
  };

  const handleCompleteSale = (type) => {
    if (parseFloat(subtotal) <= parseFloat(cashReceived)) {
      setPaymentType(type);
      setViews(true);
    } else {
      setNotification(400, "Cash is less then total");
    }
  };
  return (
    <Card className="py-5 px-2">
      <CardBody>
        <Row className="align-items-center">
          <Col lg={6}>
            <h3>Amount to Pay</h3>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Input
                placeholder="Enter Price"
                type="number"
                className="text-right"
                value={cashReceived}
                onChange={handleCashReceived}
              />
              <label>Edit to make a partial payment</label>
            </FormGroup>
          </Col>
          <Col lg={4}>
            <Button
              className="w-100"
              onClick={() => handleCompleteSale("cash")}
            >
              <h4 className="m-0 p-0">Cash</h4>
            </Button>
          </Col>
          <Col lg={4}>
            <Button
              className="w-100"
              onClick={() => handleCompleteSale("debit")}
            >
              <h4 className="m-0 p-0">Debit</h4>
            </Button>
          </Col>
          <Col lg={4}>
            <Button
              className="w-100"
              onClick={() => handleCompleteSale("credit")}
            >
              <h4 className="m-0 p-0">Credit</h4>
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
