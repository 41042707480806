/*!

=========================================================
* Paper Dashboard React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import React from "react";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import "bootstrap/dist/css/bootstrap.css";
// import "assets/scss/paper-dashboard.scss?v=1.3.0";
// import "assets/demo/demo.css";
// import "perfect-scrollbar/css/perfect-scrollbar.css";
// import "./assets/css/custom.css"
// import AdminLayout from "layouts/Admin.js";
// import { ContextProvider } from "contexts/ContextProvider";
// import Guest from "layouts/Guest";

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <BrowserRouter>
//     <ContextProvider>
//     <Switch>
//       <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
//       <Route path="/guest" render={(props) => <Guest {...props} />} />
//       <Redirect to="/admin/dashboard" />
//     </Switch>
//     </ContextProvider>
//   </BrowserRouter>
// );

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./assets/css/custom.css"
import AdminLayout from "./layouts/Admin.js";
import Guest from "./layouts/Guest";
import App from './App'
import { ContextProvider } from './contexts/ContextProvider'
import './index.css'
// import routes from './routes.js'
const rout = 
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.Fragment>
    <BrowserRouter>
      <ContextProvider>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/guest" render={(props) => <Guest {...props} />} />
        <Redirect to="/admin/dashboard" />
      </Switch>
      </ContextProvider>
    </BrowserRouter>
  </React.Fragment>,
)
