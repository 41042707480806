import axios from "axios";

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_KEY}/api`
});

axiosClient.interceptors.request.use( (config) => {
    const token = localStorage.getItem('ACCESS_TOKEN_CELL_TIMES');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
} );

axiosClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const {response} = error;
    if (response.status === 401){
        localStorage.removeItem('ACCESS_TOKEN_CELL_TIMES');
    }

    throw error;
})

export default axiosClient;