import React, { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import AmountPay from "./Components/AmountPay";
import FirstPage from "./FirstPage";

export default function Sale() {
  const [progress, setProgress] = useState(0);
  const [sale, setSale] = useState([]);
  const [coll, setColl] = useState([]);
  const [veiw, setView] = useState(0);
  const [type, setType] = useState(0);
  const [taxType, setTaxType] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [dValue, setDValue] = useState(0);
  const [tax, setTax] = useState(11);
  const [tValue, setTValue] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [dStatus, setDStatus] = useState(false);
  const [tStatus, setTStatus] = useState(true);
  const [notes, setNotes] = useState("");
  const [nStatus, setNStatus] = useState(false);
  const [cashReceived, setCashReceived] = useState(0);
  const [change, setChange] = useState(0);
  const [registorStatus, setRegistorStatus] = useState(null);
  const [paymentType, setPaymentType] = useState("cash");
  const [views, setViews] = useState(false);
  const [subtotal2, setSubtotal2] = useState(0);
  const [registorId, setRegistorId] = useState(null);
  const { setNotification } = useStateContext();
  const handleCompleteSave = () => {
    const payload = {
      registorId: registorId,
      sale: sale,
      discountType: type,
      taxType: taxType,
      discount: discount,
      dValue: dValue,
      tax: tax,
      tValue: tValue,
      total: subtotal,
      subtotal: subtotal2,
      cashReceived: cashReceived,
      change: change,
      notes: notes,
      paymentType: paymentType,
    };
    setProgress(25);
    axiosClient
      .post("/complete-sale", payload)
      .then((data) => {
        setProgress(100);
        setNotification(200, "Sale Complete Successfully");
        setColl([]);
        setSale([]);
        setView(0);
        setType(0);
        setTaxType(0);
        setDiscount(0);
        setDValue(0);
        setTax(11);
        setTValue(0);
        setSubtotal(0);
        setDStatus(false);
        setTStatus(true);
        setCashReceived(0);
        setChange(0);
        setRegistorStatus(null);
        setViews(false);
        setSubtotal2(0);
        setRegistorId(null);
        setNotes("");
        setNStatus(false);
        setPaymentType("cash");
      })
      .catch((data) => {
        setProgress(100);
      });
  };
  return (
    <div className="content">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {veiw === 0 ? (
        <FirstPage
          progress={progress}
          setProgress={setProgress}
          sale={sale}
          setSale={setSale}
          coll={coll}
          setColl={setColl}
          setView={setView}
          veiw={veiw}
          discount={discount}
          setDiscount={setDiscount}
          type={type}
          setType={setType}
          tax={tax}
          setTax={setTax}
          taxType={taxType}
          setTaxType={setTaxType}
          subtotal={subtotal}
          setSubtotal={setSubtotal}
          dStatus={dStatus}
          setDStatus={setDStatus}
          tStatus={tStatus}
          setTStatus={setTStatus}
          dValue={dValue}
          setDValue={setDValue}
          tValue={tValue}
          setTValue={setTValue}
          registorStatus={registorStatus}
          setRegistorStatus={setRegistorStatus}
          subtotal2={subtotal2}
          setSubtotal2={setSubtotal2}
          registorId={registorId}
          setRegistorId={setRegistorId}
          notes={notes}
          setNotes={setNotes}
          nStatus={nStatus}
          setNStatus={setNStatus}
        />
      ) : (
        <AmountPay
          progress={progress}
          setProgress={setProgress}
          sale={sale}
          setSale={setSale}
          coll={coll}
          setColl={setColl}
          setView={setView}
          veiw={veiw}
          discount={discount}
          setDiscount={setDiscount}
          type={type}
          setType={setType}
          tax={tax}
          setTax={setTax}
          taxType={taxType}
          setTaxType={setTaxType}
          subtotal={subtotal}
          setSubtotal={setSubtotal}
          dStatus={dStatus}
          setDStatus={setDStatus}
          tStatus={tStatus}
          setTStatus={setTStatus}
          cashReceived={cashReceived}
          setCashReceived={setCashReceived}
          change={change}
          setChange={setChange}
          dValue={dValue}
          setDValue={setDValue}
          tValue={tValue}
          setTValue={setTValue}
          views={views}
          setViews={setViews}
          handleCompleteSave={handleCompleteSave}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      )}
    </div>
  );
}
