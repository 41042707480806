import React from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import Alert from "../Alert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect } from "react";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import LoadingBar from "react-top-loading-bar";

export default function PrinterSettings() {
  const [header, setHeader] = useState("");
  const [footer, setFooter] = useState("");
  const [printer, setPrinter] = useState({});
  const [errors, setErrors] = useState(null);
  const [progress, setProgress] = useState(0);
  const { setNotification } = useStateContext();

  useEffect(() => {
    setProgress(25);
    axiosClient
      .get("/printer-settings")
      .then(({ data }) => {
        if (data.data.length > 0) {
          setPrinter(data.data[0]);
          setHeader(data.data[0].header);
          setFooter(data.data[0].footer);
        }
        setProgress(100);
      })
      .catch((data) => {
        setProgress(100);
      });
  }, []);

  const SubmitForm = (ev) => {
    ev.preventDefault();
    const payload = { ...printer };
    payload.header = header;
    payload.footer = footer;
    setProgress(25);
    if (printer.id) {
      axiosClient
        .put(`/printer-settings/${payload.id}`, payload)
        .then((data) => {
          setProgress(100);
          setNotification(200, "Printer Settings Updated");
        })
        .catch((data) => {
          setProgress(100);
          setNotification(400, "Some Error Occured");
        });
    } else {
      axiosClient
        .post("/printer-settings", payload)
        .then((data) => {
          setProgress(100);
          setNotification(200, "Printer Settings Saved");
        })
        .catch((data) => {
          setProgress(100);
          setNotification(400, "Some Error Occured");
        });
    }
  };
  return (
    <div className="content">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card className="card-user">
        <CardHeader>
          <CardTitle tag="h5">Printer Settings</CardTitle>
          {errors && (
            <div className="px-3">
              <Alert errors={errors} />
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Form onSubmit={SubmitForm}>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label>Header</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={header}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //   console.log({ event, editor, data });
                      setHeader(data);
                    }}
                    onBlur={(event, editor) => {
                      //   console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      //   console.log("Focus.", editor);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label>Footer</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={footer}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      //   console.log({ event, editor, data });
                      setFooter(data);
                    }}
                    onBlur={(event, editor) => {
                      //   console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      //   console.log("Focus.", editor);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button className="btn-round" color="primary" type="submit">
                  {printer.id ? "update" : "save"}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
