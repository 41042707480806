import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoadingBar from "react-top-loading-bar";
import axiosClient from "../../../axios-client";
import moment from "moment";
import ItemModal from "./ItemModal";
import { AiFillEdit } from "react-icons/ai";

export default function ViewRegistorHistory() {
  const [progress, setProgress] = useState(0);
  const [content, setContent] = useState({});
  const [itemModal, setItemModal] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setProgress(25);
    axiosClient.get(`/registor-sale/${id}`).then(({ data }) => {
      setContent(data);
      setProgress(100);
      if (data) {
        if (data.registor_sale.length > 0) {
          let iModal = [...itemModal];
          data.registor_sale.forEach((element) => {
            iModal.push(false);
          });
          setItemModal(iModal);
        }
      }
    });
  }
  const handleItemModal = (ev, index) => {
    const list = [...itemModal];
    list[index] = true;
    setItemModal(list);
  };

  const handleCountedCash = (ev) => {
    const data = { ...content };
    if(ev.target.value){
      data.counted_cash = ev.target.value;
      data.close_float_cash = ev.target.value;
      data.diiference = parseFloat(
        parseFloat(ev.target.value) - parseFloat(data.expected_cash)
      ).toFixed(2);
    }
    else{
      data.counted_cash = 0;
      data.close_float_cash = 0;
      data.diiference = parseFloat(
        parseFloat(0) - parseFloat(data.expected_cash)
      ).toFixed(2);
    }

    setContent(data);
  };

  const handleUpdateRegistor = () => {
    setProgress(25);
    axiosClient.post('update-registor-data', content).then((data)=>{
      console.log(data);
      setEditStatus(false);
      getData();
    })
  }
  return (
    <div className="content">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <CardHeader>
          <CardTitle tag="h4">View Registor Details</CardTitle>
          {content && content.id && (
            <CardBody>
              <Row>
                <Col lg={6}>
                  <h6 className="m-0 p-0">Registor Details</h6>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col lg={3}>
                      <small>
                        <h6 className="m-0 p-0 py-1">Outlet</h6>
                      </small>
                      <small>
                        <h6 className="m-0 p-0 py-1">Registor</h6>
                      </small>
                      <small>
                        <h6 className="m-0 p-0 py-1">Clouser#</h6>
                      </small>
                      <small>
                        <h6 className="m-0 p-0 py-1">Opening time</h6>
                      </small>
                    </Col>
                    <Col lg={9}>
                      <small className="py-1">Main Outlet</small>
                      <br />
                      <small className="py-1">Main Registor</small>
                      <br />
                      <small className="py-1">{content.id}</small>
                      <br />
                      <small className="py-1">
                        {moment(content.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </small>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className="pt-5">
                  <h5 className="m-0 p-0">Open Float Details</h5>
                  <div className="py-2">
                    {content.open_float_cash && (
                      <h6 className="m-0 p-0 py-1">
                        Cash: ${content.open_float_cash}
                      </h6>
                    )}
                    {content.open_note && (
                      <p className="m-0 p-0 py-1">Notes: {content.open_note}</p>
                    )}
                  </div>
                </Col>
                <Col lg={6} className="pt-5">
                  <h5 className="m-0 p-0">Close Float Details</h5>
                  <div className="py-2">
                    {content.close_float_cash && (
                      <h6 className="m-0 p-0 py-1">
                        Cash: ${content.close_float_cash}
                      </h6>
                    )}
                    {content.close_note && (
                      <p className="m-0 p-0 py-1">
                        Notes: {content.close_note}
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
              <Table className="table-hover" responsive>
                <thead className="text-primary">
                  <tr>
                    <th>EXPECTED($)</th>
                    <th>COUNTED($)</th>
                    <th>DIFFERENCES($)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{content.expected_cash && content.expected_cash}</td>
                    <td>
                      {content.counted_cash !== "" && editStatus ? (
                        <div className="d-flex">
                          <Input
                            type="number"
                            value={content.counted_cash}
                            onChange={handleCountedCash}
                            className="w-75"
                          />
                          <Button className="w-25" onClick={handleUpdateRegistor}>Update</Button>
                        </div>
                      ) : (
                        <>
                          <span>{content.counted_cash}</span>
                          <span
                            className="ml-3"
                            onClick={() => setEditStatus(true)}
                          >
                            <AiFillEdit />
                          </span>
                        </>
                      )}
                    </td>
                    <td>{content.diiference && content.diiference}</td>
                  </tr>
                </tbody>
              </Table>
              <Row className="py-5">
                {content &&
                  content.registor_sale &&
                  content.registor_sale.length > 0 &&
                  content.registor_sale.map((con, index) => (
                    <Col lg={6} key={index} className="mb-4">
                      {con.sale_record && (
                        <Row>
                          <Col lg={3}>
                            <h6 className="p-0 m-0">Sale Details: </h6>
                          </Col>
                          <Col lg={6}>
                            <span>
                              <b>Sale No:</b> {index + 1}
                            </span>
                            <br />
                            <span>
                              <b>Sub Total:</b> ${con.sale_record.sub_total}
                            </span>
                            <br />
                            {con.sale_record.tax !== "0" && (
                              <div>
                                <b>Tax:</b>{" "}
                                {con.sale_record.tax_type === "0" ? "%" : "$"}
                                {con.sale_record.tax}
                              </div>
                            )}
                            {con.sale_record.discount !== "0" && (
                              <div>
                                <b>Discount:</b>{" "}
                                {con.sale_record.discount_type === "0"
                                  ? "%"
                                  : "$"}
                                {con.sale_record.discount}
                              </div>
                            )}
                            <span>
                              <b>Total:</b> ${con.sale_record.total}
                            </span>
                            <br />
                            <span>
                              <b>Payment Type:</b>{" "}
                              {con.sale_record.payment_type}
                            </span>
                            <br />
                            <span>
                              <b>Payment Received:</b> $
                              {con.sale_record.cash_recevied}
                            </span>
                            <br />
                            <span>
                              <b>Change:</b> ${con.sale_record.change}
                            </span>
                            <br />
                          </Col>
                          <Col lg={3}>
                            <Button
                              className="btn btn-danger w-100"
                              onClick={(ev) => handleItemModal(ev, index)}
                            >
                              View Items
                            </Button>
                            {itemModal[index] && (
                              <ItemModal
                                itemModal={itemModal}
                                setItemModal={setItemModal}
                                items={con.sale_record.sale_items}
                                mIndex={index}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  ))}
              </Row>
            </CardBody>
          )}
        </CardHeader>
      </Card>
    </div>
  );
}
