import React from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import LoadingBar from "react-top-loading-bar";
import { useState } from "react";
import Alert from "../../Alert";
import { useStateContext } from "../../../contexts/ContextProvider";
import axiosClient from "../../../axios-client";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function OpenRegistor(props) {
  const { content, setContent, setRegistorStatus, setSubmitStatus } = props;
  const { setNotification } = useStateContext();
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState(null);
  const onSubmit = (ev) => {
    ev.preventDefault();
    setProgress(25);
    setErrors(null);
    const payload = content;
    payload.status = "open";
    axiosClient
      .post("/registors", payload)
      .then((data) => {
        setSubmitStatus(true);
        setRegistorStatus(1);
        setProgress(100);
        setNotification(200, "Registor Open SuccessFully");
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setProgress(100);
          setErrors(response.data.errors);
          setNotification(400, "Some Error Occurs");
        }
      });
  };
  return (
    <div>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div style={{ background: "#f2f2f2" }}>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <h6 className="m-0 p-0">
                Set an opening float to open the register and make a sale.
              </h6>
            </Col>
            <Col lg={6} className="text-right">
              <Link to="/admin/history-registor" className="btn btn-primary">Registor List</Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="py-3">
          <Col lg={12}>
            {errors && (
              <div className="px-3">
                <Alert errors={errors} />
              </div>
            )}
          </Col>
          <Col lg={3}></Col>
          <Col lg={6}>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <label>Opening Float</label>
                <Input
                  placeholder="Enter Opening Float"
                  type="number"
                  value={content.open_float_cash}
                  onChange={(ev) =>
                    setContent({ ...content, open_float_cash: ev.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label>Notes</label>
                <textarea
                  className="form-control"
                  placeholder="Enter Opening Float Notes"
                  onChange={(ev) =>
                    setContent({ ...content, open_note: ev.target.value })
                  }
                >
                  {content.open_note}
                </textarea>
              </FormGroup>
              <FormGroup>
                <button className="btn btn-primary w-100" type="submit">
                  Open Registor
                </button>
              </FormGroup>
            </Form>
          </Col>
          <Col lg={3}></Col>
        </Row>
      </Container>
    </div>
  );
}
