import React from "react";
import { useState } from "react";
import { forwardRef } from "react";
import moment from "moment";

const PrintReceipt = forwardRef((props, ref) => {
  const { sale, items, printer } = props;
  const date = moment().format("lll");
  return (
    <div ref={ref}>
      <div style={{ padding: "10px 30px 10px 20px", width: "455px" }}>
        {/* Top Header */}
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>{date}</div>
          <div style={{ width: "50%", textAlign: "right" }}>
            Cell Times | NetBotz
          </div>
        </div>

        {/* Shop Name */}
        <div style={{ display: "flex" }}>
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
          >
            <h3 style={{ margin: "0", padding: "0" }}>Cell Time Accessories</h3>
            <h5 style={{ margin: "0", padding: "0" }}>Main Outlet</h5>
            {printer && printer.header && (
              <div style={{ textAlign: "left" }}>
                <div dangerouslySetInnerHTML={{ __html: printer.header }}></div>
              </div>
            )}
            <h6 style={{ margin: "0", padding: "0", marginTop: "10px" }}>
              * * * * Print * * * *
            </h6>
          </div>
        </div>
        {/* Sale Number and Date */}
        <div
          style={{
            borderTop: "1px dashed black",
            borderBottom: "1px dashed black",
            marginTop: "20px",
            padding: "10px 0",
          }}
        >
          <h5 style={{ margin: "0", padding: "0", textAlign: "center" }}>
            Receipt / Tax Invoice #CTA-{sale.sale_num}
          </h5>
          <h6 style={{ margin: "0", padding: "0", textAlign: "center" }}>
            {moment(sale.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </h6>
        </div>

        {/* Sale Items */}
        {items && items.length > 0 && (
          <div
            style={{
              borderBottom: "1px dashed black",
              padding: "15px 0",
            }}
          >
            {items.map((c, i) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <h6
                    style={
                      c.return_status === "0"
                        ? { margin: "0", padding: "0" }
                        : {
                            margin: "0",
                            padding: "0",
                            color: "red",
                            textDecoration: "line-through",
                          }
                    }
                  >
                    {c.item_name}
                  </h6>
                </div>
                <div
                  style={
                    c.return_status === "0"
                      ? {
                          width: "70%",
                          paddingLeft: "20px",
                        }
                      : {
                          width: "70%",
                          paddingLeft: "20px",
                          color: "red",
                          textDecoration: "line-through",
                        }
                  }
                >
                  <div>
                    {c.item_qty} x ${c.item_price}
                  </div>
                  {c.item_discount !== "0" && (
                    <div>Disc: {c.item_discount}%</div>
                  )}
                  {c.item_note && (
                    <div>
                      Note: <pre style={{ border: "none" }}>{c.item_note}</pre>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: "30%",
                  }}
                >
                  <div style={{ textAlign: "right" }}>
                    {c.item_discount !== "0" ? (
                      <>
                        <div
                          style={
                            c.return_status === "0"
                              ? { color: "red" }
                              : { textDecoration: "line-through" }
                          }
                        >
                          $
                          {parseFloat(c.item_qty).toFixed(2) *
                            parseFloat(c.item_price).toFixed(2) -
                            (parseFloat(c.item_discount).toFixed(2) / 100) *
                              (parseFloat(c.item_qty).toFixed(2) *
                                parseFloat(c.item_price).toFixed(2))}
                        </div>
                        <strike>
                          $
                          {parseFloat(c.item_qty).toFixed(2) *
                            parseFloat(c.item_price).toFixed(2)}
                        </strike>
                      </>
                    ) : (
                      <>
                        $
                        {parseFloat(c.item_qty).toFixed(2) *
                          parseFloat(c.item_price).toFixed(2)}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Sale Notes */}
        {sale.notes && (
          <div
            style={{
              borderBottom: "1px dashed black",
              padding: "15px 0",
            }}
          >
            Notes: <pre style={{ border: "none" }}>{sale.notes}</pre>
          </div>
        )}

        {/* Sale Subtotal and Sale Discount */}
        <div
          style={{
            padding: "15px 0",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "70%" }}>
              <h6 style={{ margin: "0", padding: "0" }}>Subtotal</h6>
            </div>
            <div style={{ width: "30%", textAlign: "right" }}>
              <div>${sale.sub_total}</div>
            </div>
            <div style={{ width: "70%" }}>
              <h6 style={{ margin: "0", padding: "0" }}>Additional Discount</h6>
            </div>
            <div style={{ width: "30%", textAlign: "right" }}>
              <div>
                {sale.discount_type === "0" ? (
                  <>{sale.discount}%</>
                ) : (
                  <>${sale.discount}</>
                )}
              </div>
            </div>
            <div style={{ width: "70%" }}>
              <h6 style={{ margin: "0", padding: "0" }}>Tax</h6>
            </div>
            <div style={{ width: "30%", textAlign: "right" }}>
              <div>
                {sale.tax_type === "0" ? <>{sale.tax}%</> : <>${sale.tax}</>}
              </div>
            </div>
          </div>
        </div>

        {/* Sale Total Amount */}
        <div
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            padding: "15px 0",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "70%" }}>
              <h6 style={{ margin: "0", padding: "0" }}>TOTAL</h6>
            </div>
            <div style={{ width: "30%", textAlign: "right" }}>
              <div>${sale.total}</div>
            </div>
          </div>
        </div>

        {/* Cash */}
        <div
          style={{
            borderBottom: "1px dashed black",
            padding: "15px 0",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "70%", textTransform: "capitalize" }}>
              {sale.payment_type}
            </div>
            <div style={{ width: "30%", textAlign: "right" }}>
              ${sale.cash_recevied}
            </div>
            <div style={{ width: "70%" }}>Change</div>
            <div style={{ width: "30%", textAlign: "right" }}>
              ${sale.change}
            </div>
          </div>
        </div>

        {printer && printer.footer && (
          <div dangerouslySetInnerHTML={{ __html: printer.footer }}></div>
        )}
      </div>
    </div>
  );
});

export default PrintReceipt;
