import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import LoadingBar from 'react-top-loading-bar'
import { useHistory, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../Alert';

export default function ProductForm() {
    const {id} = useParams();
    const { setNotification } = useStateContext();
    const [product, setProduct] = useState({
        id: null,
        name: '',
        price: '',
        category_id: ''
    });
    const [category, setCategory] = useState([]);
    const [progress, setProgress] = useState(0);
    const [errors, setErrors] = useState(null);
    const hisotry = useHistory();


    useEffect(()=>{
        setProgress(25);
        axiosClient.get(`/category_list/`)
        .then(({data})=>{
            setProgress(100);
            setCategory(data.data);
        })
        .catch(()=>{
            setProgress(100);
        })
        if(id){
            setProgress(25);
            axiosClient.get(`/products/${id}`)
            .then(({data})=>{
                setProgress(100);
                setProduct(data.data);
            })
            .catch(()=>{
                setProgress(100);
            })
        }
    }, [])

    const SubmitForm = (ev) => {
        ev.preventDefault();
        setProgress(25)
        setErrors(null)
        if(id){
            axiosClient.put(`/products/${product.id}`, product)
            .then(()=>{
                setProgress(100)
                setNotification(200,'product was successfully updated');
                hisotry.push('/admin/product');
            })
            .catch(err => {
                const response = err.response;
                if(response && response.status === 422){
                    setErrors( response.data.errors );    
                    setProgress(100)                
                }
            })
        }
        else{
            axiosClient.post(`/products`, product)
            .then(()=>{
                setProgress(100)
                setNotification(200,'Product was successfully created');
                hisotry.push('/admin/product');
            })
            .catch(err => {
                const response = err.response;
                if(response && response.status === 422){
                    setProgress(100)
                    setErrors( response.data.errors );      
                    setNotification(400,'Some Error Occurs');              
                }
            })
        }
    }
  return (
    <div className='content'>
    <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
      <Row>
        <Col lg={12}>
            <Card className="card-user">
                <CardHeader>
                <CardTitle tag="h5">Product Form</CardTitle>
                {
                    errors && 
                    <div className='px-3'>
                    <Alert errors={errors} />
                    </div>
                }
                </CardHeader>
                <CardBody>
                <Form onSubmit={SubmitForm}>
                    <Row>
                    <Col md="12">
                        <FormGroup>
                        <label>Name</label>
                        <Input
                            placeholder="Enter Name"
                            type="text"
                            onChange={ev=>setProduct({...product, name: ev.target.value})}
                            value={product.name}
                        />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                        <label>Price</label>
                        <Input
                            placeholder="Enter Price"
                            type="number"
                            onChange={ev=>setProduct({...product, price: ev.target.value})}
                            value={product.price}
                        />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                        <label>product</label>
                        <select className='form-control' value={product.category_id} onChange={ ev => setProduct({...product, category_id: ev.target.value})}>
                                <option>--select--</option>
                            {
                                category.length > 0 && category.map((c, i)=> (
                                    <option value={c.id} key={i}>{c.name}</option>       
                                ))   
                            }
                        </select>
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={12}>
                        <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        >
                        { id ? 'Update': 'Save'}
                        </Button>
                    </Col>
                    </Row>
                </Form>
                </CardBody>
            </Card>
        </Col>
      </Row>
    </div>
  )
}
