import React, { useEffect, useState } from "react";
import { FiChevronDown, FiChevronRight, FiTrash2 } from "react-icons/fi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import DiscountModal from "./DiscountModal";
import TaxModal from "./TaxModal";

export default function OpenSale(props) {
  const {
    sale,
    setSale,
    coll,
    setColl,
    setView,
    discount,
    setDiscount,
    type,
    setType,
    tax,
    setTax,
    taxType,
    setTaxType,
    subtotal,
    setSubtotal,
    dStatus,
    setDStatus,
    tStatus,
    setTStatus,
    dValue,
    setDValue,
    tValue,
    setTValue,
    subtotal2,
    setSubtotal2,
    notes,
    setNotes,
    nStatus,
    setNStatus,
  } = props;
  const [discountModal, setDiscountModal] = useState(false);
  const [taxModal, setTaxModal] = useState(false);
  const [collapse, setCollapse] = useState([]);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (coll) {
      if (coll.length > 0) {
        let ary = [];
        coll.forEach((element, index) => {
          ary[index] = false;
        });
        setCollapse(ary);
      }
    }
  }, [coll]);
  const getSubtotal = () => {
    let sTotal = 0;
    let tQty = 0;
    coll.forEach((element) => {
      sTotal =
        sTotal +
        (parseFloat(element.price) * parseFloat(element.quantity) -
          parseFloat(element.price) *
            parseFloat(element.quantity) *
            (element.discount / 100));
      tQty += parseFloat(element.quantity);
    });
    setSubtotal2(sTotal.toFixed(2));
    setSubtotal(sTotal.toFixed(2));
    setQuantity(tQty);
  };
  useEffect(() => {
    if (coll) {
      if (coll.length > 0) {
        getSubtotal();
      } else {
        setSubtotal(0);
        setSubtotal2(0);
        setQuantity(0);
      }
    }
  }, [coll]);

  const handleRemoveSaleItem = (ev, index) => {
    const list = [...sale];
    list.splice(index, 1);
    setSale(list);
    setColl(list);
  };

  const handleCollapse = (ev, index) => {
    const list = [...collapse];
    if (list[index]) {
      list[index] = false;
    } else {
      list[index] = true;
    }
    setCollapse(list);
  };

  const handleSaleContent = (ev, index) => {
    const { name, value } = ev.target;
    if (value) {
      const list = [...sale];
      list[index][name] = value;
      setSale(list);
    } else {
      const list = [...sale];
      list[index][name] = 0;
      setSale(list);
    }

    getSubtotal();
  };
  const handleSubmitAmount = (ev) => {
    if (coll.length > 0) {
      setView(1);
    }
  };

  const handleDiscountModal = () => {
    setDiscountModal(true);
  };
  const handleTaxModal = () => {
    setTaxModal(true);
  };

  const removeGlobalDiscount = () => {
    setDiscount(0);
    setSubtotal(subtotal2 + dValue);
    setDValue(0);
    setDStatus(false);
  };
  const removeGlobalTax = () => {
    setTax(0);
    setSubtotal(subtotal2 - tValue);
    setTValue(0);
    setTStatus(false);
  };

  const handleCloseNotes = () => {
    setNStatus(false);
    setNotes("");
  };
  return (
    <>
      <Card className="card-user ct-min-card-closed-height">
        <CardBody>
          <div
            style={{ height: "450px", overflowY: "auto", overflowX: "hidden" }}
          >
            {/* <Alert color="danger">
            You don’t have enough inventory available.
          </Alert> */}
            {sale &&
              sale.length > 0 &&
              sale.map((ele, index) => (
                <div key={index}>
                  <div
                    onClick={(ev) => handleCollapse(ev, index)}
                    className="border"
                  >
                    <Row className="align-items-center p-3">
                      <Col lg={1}>
                        <h6 className="m-0">
                          {collapse ? <FiChevronDown /> : <FiChevronRight />}
                        </h6>
                      </Col>
                      <Col lg={7}>
                        <h6 className="d-inline mb-0">{ele.name}</h6>
                      </Col>
                      <Col lg={3}>
                        <h6 className="text-right mb-0">{ele.price}</h6>
                      </Col>
                      <Col lg={1}>
                        <h6
                          className="m-0"
                          onClick={(ev) => handleRemoveSaleItem(ev, index)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <FiTrash2 />{" "}
                        </h6>
                      </Col>
                    </Row>
                  </div>
                  <Collapse isOpen={collapse[index]}>
                    <Card>
                      <div className="p-2">
                        <Row>
                          <Col lg={4}>
                            <FormGroup>
                              <label>Quantity</label>
                              <Input
                                placeholder="Enter Quantity"
                                type="number"
                                className="text-right"
                                name="quantity"
                                value={ele.quantity}
                                onChange={(ev) => handleSaleContent(ev, index)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={4}>
                            <FormGroup>
                              <label>Price</label>
                              <Input
                                placeholder="Enter Price"
                                type="number"
                                className="text-right"
                                value={ele.price}
                                name="price"
                                onChange={(ev) => handleSaleContent(ev, index)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={4}>
                            <FormGroup>
                              <label>Discount (%)</label>
                              <Input
                                placeholder="Enter Discount (%)"
                                type="number"
                                className="text-right"
                                name="discount"
                                value={ele.discount}
                                onChange={(ev) => handleSaleContent(ev, index)}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col lg={12}>
                                        <h6 className="text-danger m-0">
                                            <span><BsFillExclamationTriangleFill /></span>  You only have 0 available. <span style={{ cursor: 'pointer' }}>Continue anyway?</span>
                                        </h6>
                                    </Col> */}
                          <Col lg={12}>
                            <FormGroup>
                              <label>Notes</label>
                              <textarea
                                className="form-control"
                                name="notes"
                                onChange={(ev) => handleSaleContent(ev, index)}
                              >
                                {ele.notes}
                              </textarea>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Collapse>
                </div>
              ))}
          </div>
          {/* Sale Genration Setup */}
          <Row className="align-items-center py-3">
            <Col lg={4}>
              <h5 className="my-0">ADD</h5>
            </Col>
            <Col lg={1}></Col>
            <Col lg={3} className="text-right">
              <span
                className="font-weight-bold"
                style={{ cursor: "pointer" }}
                onClick={handleDiscountModal}
              >
                Disocunt
              </span>
            </Col>
            <Col lg={2} className="text-right">
              <span
                className="font-weight-bold"
                style={{ cursor: "pointer" }}
                onClick={handleTaxModal}
              >
                Tax
              </span>
            </Col>
            <Col lg={2} className="text-right">
              <span
                className="font-weight-bold"
                style={{ cursor: "pointer" }}
                onClick={() => setNStatus(true)}
              >
                Note
              </span>
            </Col>
          </Row>
          {nStatus && (
            <Row className="align-items-center">
              <Col lg={11}>
                <FormGroup>
                  <label>Note</label>
                  <textarea
                    className="form-control"
                    name="notes"
                    rows={1}
                    onChange={(ev) => setNotes(ev.target.value)}
                  >
                    {notes}
                  </textarea>
                </FormGroup>
              </Col>
              <Col lg={1} style={{fontSize: '20px'}}>
                <FiTrash2 onClick={handleCloseNotes} />
              </Col>
            </Row>
          )}
          <Row className="border-top py-3 align-items-center">
            <Col lg={6}>
              <h6 className="mb-0">Subtotal</h6>
            </Col>
            <Col lg={6}>
              <h5 className="mb-0 text-right font-weight-bold">${subtotal}</h5>
            </Col>
          </Row>
          {dStatus && (
            <Row className="border-top py-3 align-items-center">
              <Col lg={6}>
                <h6 className="mb-0">Discount</h6>
              </Col>
              <Col lg={6}>
                <h5 className="mb-0 text-right font-weight-bold">
                  {type === 0 ? `${discount}%` : `$${discount}`}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={removeGlobalDiscount}
                  >
                    <FiTrash2 />
                  </span>
                </h5>
              </Col>
            </Row>
          )}
          {tStatus && (
            <Row className="border-top py-3 align-items-center">
              <Col lg={6}>
                <h6 className="mb-0">Tax No Tax</h6>
              </Col>
              <Col lg={6}>
                <h5 className="mb-0 text-right font-weight-bold">
                  {taxType === 0 ? `${tax}%` : `$${tax}`}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={removeGlobalTax}
                  >
                    <FiTrash2 />
                  </span>
                </h5>
              </Col>
            </Row>
          )}
          <Row
            className={
              coll && coll.length > 0
                ? "align-items-center bg-primary py-2"
                : "align-items-center bg-danger py-2"
            }
            style={{ cursor: "pointer" }}
            onClick={handleSubmitAmount}
          >
            <Col lg={6}>
              <h6 className="my-0">
                Pay <span style={{ fontSize: "12px" }}>{quantity} item</span>
              </h6>
            </Col>
            <Col lg={6} className="text-right">
              <h4 className="my-0 font-weight-bold">${subtotal}</h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DiscountModal
        discountModal={discountModal}
        setDiscountModal={setDiscountModal}
        discount={discount}
        setDiscount={setDiscount}
        type={type}
        setType={setType}
        subtotal={subtotal}
        setSubtotal={setSubtotal}
        subtotal2={subtotal2}
        setSubtotal2={setSubtotal2}
        dStatus={dStatus}
        setDStatus={setDStatus}
        tax={tax}
        setTax={setTax}
        taxType={taxType}
        setTaxType={setTaxType}
        dValue={dValue}
        setDValue={setDValue}
        tValue={tValue}
        setTValue={setTValue}
      />
      <TaxModal
        taxModal={taxModal}
        setTaxModal={setTaxModal}
        tax={tax}
        setTax={setTax}
        taxType={taxType}
        setTaxType={setTaxType}
        subtotal={subtotal}
        setSubtotal={setSubtotal}
        subtotal2={subtotal2}
        setSubtotal2={setSubtotal2}
        tStatus={tStatus}
        setTStatus={setTStatus}
        discount={discount}
        setDiscount={setDiscount}
        type={type}
        setType={setType}
        dValue={dValue}
        setDValue={setDValue}
        tValue={tValue}
        setTValue={setTValue}
      />
    </>
  );
}
