import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table } from 'reactstrap'
import LoadingBar from 'react-top-loading-bar'
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useStateContext } from '../../contexts/ContextProvider';
import Pagination from '../Pagination';
import axiosClient from '../../axios-client';

export default function ProductList() {
  const [products, setProducts] = useState([]);
    const [links, setLinks] = useState({
        first: null,
        last: null,
        next: null,
        prev: null,
    });
    const [metaData, setMetaData] = useState({
        current_page: null,
        from: null,
        last_page: null,
        plinks: null,
        path: null,
        per_page: null,
        to: null,
        total: null,
    });
    const [progress, setProgress] = useState(0);
    const { setNotification } = useStateContext();
  useEffect(()=>{
    getProducts();
  }, []);

  const getProducts = () => {
    setProgress(25)
    axiosClient.get('/products')
      .then(({ data }) => {
          setProducts(data.data);
          setProgress(100)
          setLinks({...links, next: data.links.next, prev: data.links.prev, first: data.links.first, last: data.links.last});
          setMetaData({...metaData, current_page: data.meta.current_page, from: data.meta.from, last_page: data.meta.last_page, plinks: data.meta.links, path: data.meta.path, per_page: data.meta.per_page, to: data.meta.to, total: data.meta.total,});
      })
      .catch(() => {
        setProgress(100)
      })
  }

  const onDelete = (category) => {
    if (!window.confirm("Are you sure you want to delete this product?")) {
        return
    }
    setProgress(25);
    axiosClient.delete(`/products/${category.id}`)
    .then(() => {
        setProgress(100);
        setNotification(200,'Product was successfully deleted');
        getProducts();
    })
  }
  const handlePagnate = (type, url=null) => {
        
      if(type === 'prev' && links.prev){
          paginateData(links.prev);
      }
      else if( type === 'next' && links.next){
          paginateData(links.next);
      }
      else if( type === 'first' && links.prev ){
          paginateData(links.first);
      }
      else if( type === 'last' && links.next ){
          paginateData(links.last);
      }
      else if( type === 'single' ){
          paginateData(url);
      }
  }
  const paginateData = (aLink) => {
    setProgress(25);
    axiosClient.get(aLink)
    .then(({data})=>{
        setProducts(data.data);
        setLinks({...links, next: data.links.next, prev: data.links.prev, first: data.links.first, last: data.links.last});
        setMetaData({...metaData, current_page: data.meta.current_page, from: data.meta.from, last_page: data.meta.last_page, plinks: data.meta.links, path: data.meta.path, per_page: data.meta.per_page, to: data.meta.to, total: data.meta.total,});
        setProgress(100);
    })
}
  return (
    <div className="content">
    <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col lg={6}>
                  <CardTitle tag="h4">Products List</CardTitle>
                </Col>
                <Col lg={6}>
                  <div className="text-right">
                    <Link to="product/new" className="btn btn-primary">Add New</Link>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table className="table-hover" responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Sr#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Category</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    {
                        products === '' ? <> 
                        <tr>
                            <th colSpan={3}>
                                <h3 className="text-center">
                                    Please enter category to display list
                                </h3>
                            </th>
                        </tr></> : products.map((u ,index) => (
                        <tr key={u.id}>
                            <td><strong>{metaData.from+index}</strong></td>
                            <td>{u.name}</td>
                            <td>${u.price}</td>
                            <td>{u.cat_name}</td>
                            <td>
                                <Link className='btn btn-primary' to={'/admin/product/edit/' + u.id}
                                ><FiEdit /></Link>
                                <button className="btn btn-primary ml-2" onClick={ev => onDelete(u)}
                                ><RiDeleteBin5Fill /></button>
                            </td>
                        </tr>
                        ))
                    }
                </tbody>
              </Table>
              <Pagination metaData={metaData} handlePagnate={handlePagnate} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
