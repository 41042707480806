import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import PaymentSummary from "./PaymentSummary";
import moment from "moment";
import { useEffect } from "react";
import axiosClient from "../../../axios-client";
import LoadingBar from "react-top-loading-bar";
import { useStateContext } from "../../../contexts/ContextProvider";
import Alert from "../../Alert";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function CloseRegistor(props) {
  const { registorId, registorData, setSubmitStatus, setRegistorStatus } =
    props;
  const [content, setContent] = useState({});
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState(null);
  const { setNotification } = useStateContext();
  useEffect(() => {
    axiosClient.get(`registor-sale/${registorId}`).then(({ data }) => {
      let expected = 0;
      let paymentReceived = 0;
      if (data) {
        expected = expected + parseFloat(data.open_float_cash);
        if (data.registor_sale.length > 0) {
          data.registor_sale.forEach((element, index) => {
            if (element.sale_record) {
              expected = expected + parseFloat(element.sale_record.total);
              paymentReceived =
                paymentReceived + parseFloat(element.sale_record.total);
            }
          });
        }
      }
      setContent({
        ...content,
        expected_cash: expected.toFixed(2),
        user_details: data.user_details,
        open_float_cash: data.open_float_cash,
        open_note: data.open_note,
        payment_received: paymentReceived.toFixed(2),
      });
    });
  }, [registorId]);

  const handleCloseRegistor = () => {
    setProgress(25);
    const payload = content;
    payload.status = "close";
    payload.register_id = registorId;
    axiosClient
      .post("/update-registor", payload)
      .then((data) => {
        setRegistorStatus(0);
        setSubmitStatus(true);
        setProgress(100);
        setNotification(200, "Registor Closed SuccessFully");
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setProgress(100);
          setErrors(response.data.errors);
          setNotification(400, "Some Error Occurs");
        }
      });
  };
  return (
    <div>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {errors && (
        <div className="px-3">
          <Alert errors={errors} />
        </div>
      )}
      <div style={{ background: "#f2f2f2" }}>
        <Container>
          <Row className="align-items-center">
            <Col lg={8}>
              <h6 className="m-0 p-0">
                Close your register to finalize payments and sales for the day.
              </h6>
            </Col>
            <Col lg={4} className="text-right">
              <Link to="/admin/history-registor" className="btn btn-primary">
                Registor List
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="pt-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={4}>
                <h6 className="m-0 p-0">Registor Details</h6>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={3}>
                    <small>
                      <h6 className="m-0 p-0 py-1">Outlet</h6>
                    </small>
                    <small>
                      <h6 className="m-0 p-0 py-1">Registor</h6>
                    </small>
                    <small>
                      <h6 className="m-0 p-0 py-1">Clouser#</h6>
                    </small>
                    <small>
                      <h6 className="m-0 p-0 py-1">Opening time</h6>
                    </small>
                  </Col>
                  <Col lg={9}>
                    <small className="py-1">Main Outlet</small>
                    <br />
                    <small className="py-1">Main Registor</small>
                    <br />
                    <small className="py-1">{registorId}</small>
                    <br />
                    <small className="py-1">
                      {moment(registorData.created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </small>
                  </Col>
                </Row>
              </Col>
            </Row>
            {content && (
              <PaymentSummary
                pSummary={content}
                setPSumary={setContent}
                registorData={registorData}
              />
            )}
          </Col>
          <Col lg={12}>
            <h6
              className="p-0 m-0 py-3 mt-4 px-2"
              style={{ background: "rgb(242, 242, 242)" }}
            >
              Closing Summary
            </h6>
          </Col>
          <Col lg={12}>
            <FormGroup>
              <label>Notes</label>
              <textarea
                className="form-control"
                placeholder="Enter Closing Float"
                onChange={(ev) =>
                  setContent({ ...content, close_note: ev.target.value })
                }
              >
                {content.close_note}
              </textarea>
            </FormGroup>
            <Button className="btn-primary" onClick={handleCloseRegistor}>
              Close Registor
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
