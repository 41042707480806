import React, { useEffect, useState } from "react";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import OpenRegistor from "./Components/OpenRegistor";
import OpenSale from "./Components/OpenSale";
import QuickTabs from "./Components/QuickTabs";
import ReactSelect from "react-select";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";
import CloseRegistor from "./Components/CloseRegistor";
export default function FirstPage(props) {
  const {
    progress,
    setProgress,
    sale,
    setSale,
    coll,
    setColl,
    setView,
    veiw,
    discount,
    setDiscount,
    type,
    setType,
    tax,
    setTax,
    taxType,
    setTaxType,
    subtotal,
    setSubtotal,
    dStatus,
    setDStatus,
    tStatus,
    setTStatus,
    dValue,
    setDValue,
    tValue,
    setTValue,
    registorStatus,
    setRegistorStatus,
    subtotal2,
    setSubtotal2,
    registorId,
    setRegistorId,
    notes,
    setNotes,
    nStatus,
    setNStatus,
  } = props;
  const [products, setProducts] = useState([]);
  const [pstatus, setPstatus] = useState(false);
  const [qstatus, setQstatus] = useState(false);
  const [quickTab, setQuicktab] = useState([]);
  const { setNotification } = useStateContext();
  useEffect(() => {
    axiosClient
      .get("/check-registor")
      .then(({ data }) => {
        if (data.registor === 0) {
          setRegistorStatus(0);
          setRegistorId(data.id);
        } else if (data.registor === 1) {
          setRegistorStatus(1);
          setRegistorId(data.id);
        } else {
          setRegistorStatus(2);
          setRegistorId(data.id);
        }
      })
      .catch((data) => {
      });
  }, [sale]);
  useEffect(() => {
    if (!pstatus) {
      axiosClient.get("/product-list").then(({ data }) => {
        const list = data.data;
        let options = [];
        if (list.length > 0) {
          list.forEach((element, index) => {
            options[index] = {
              value: element.id,
              label: `${element.name}     ${"$" + element.price}`,
            };
          });
        }
        setPstatus(true);
        setProducts(options);
      });
    }
  }, [products, pstatus]);
  useEffect(() => {
    if (!qstatus) {
      axiosClient.get("/quicktab-list").then(({ data }) => {
        setQuicktab(data.data);
        setQstatus(true);
      });
    }
  }, [quickTab, qstatus]);

  const handleSearch = async (ev) => {
    setProgress(25);
    if (registorStatus === 1) {
      if (ev) {
        setProgress(50);
        let list = null;
        await axiosClient
          .get(`/products/${ev.value}`)
          .then(({ data }) => {
            list = data.data;
            setProgress(100);
          })
          .catch((data) => {
            setProgress(100);
          });
        if (list !== null) {
          list.quantity = 1;
          list.discount = 0;
          list.notes = "";
          setSale([...sale, list]);
          setColl([...sale, list]);
        }
      }
      setProgress(100);
    } else if (registorStatus === 0) {
      setProgress(100);
      setNotification(400, "Please Open The Registor");
    } else if (registorStatus === 2) {
      setProgress(100);
      setNotification(400, "Please Close The Registor");
    }
  };
  const handleSearch2 = async (ev) => {
    setProgress(25);
    if (registorStatus === 1) {
      if (ev) {
        setProgress(50);
        let list = null;
        await axiosClient
          .get(`/products/${ev}`)
          .then(({ data }) => {
            list = data.data;
            setProgress(100);
          })
          .catch((data) => {
            setProgress(100);
          });
        if (list !== null) {
          list.quantity = 1;
          list.discount = 0;
          list.notes = "";
          setSale([...sale, list]);
          setColl([...sale, list]);
        }
      }
      setProgress(100);
    } else if (registorStatus === 0) {
      setProgress(100);
      setNotification(400, "Please Open The Registor");
    } else if (registorStatus === 2) {
      setProgress(100);
      setNotification(400, "Please Close The Registor");
    }
  };
  return (
    <Container>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label>
              <b>Product Search</b>
            </label>
            <ReactSelect
              className="basic-single"
              isClearable={true}
              isSearchable={true}
              name="color"
              options={products}
              placeholder="Start Typing or Scanning..."
              onChange={handleSearch}
            />
          </FormGroup>
          <div className="py-4 d-flex flex-wrap">
            {quickTab &&
              quickTab.length > 0 &&
              quickTab.map((c, i) => (
                <QuickTabs
                  key={i}
                  name={c.name}
                  productId={c.product_id}
                  handleSearch={handleSearch2}
                />
              ))}
          </div>
        </Col>
        {registorStatus !== null && (
          <Col lg={6}>
            {registorStatus === 1 ? (
              <OpenSale
                sale={sale}
                setSale={setSale}
                coll={coll}
                setColl={setColl}
                setView={setView}
                veiw={veiw}
                discount={discount}
                setDiscount={setDiscount}
                type={type}
                setType={setType}
                tax={tax}
                setTax={setTax}
                taxType={taxType}
                setTaxType={setTaxType}
                subtotal={subtotal}
                setSubtotal={setSubtotal}
                dStatus={dStatus}
                setDStatus={setDStatus}
                tStatus={tStatus}
                setTStatus={setTStatus}
                dValue={dValue}
                setDValue={setDValue}
                tValue={tValue}
                setTValue={setTValue}
                subtotal2={subtotal2}
                setSubtotal2={setSubtotal2}
                notes={notes}
                setNotes={setNotes}
                nStatus={nStatus}
                setNStatus={setNStatus}
              />
            ) : registorStatus === 2 ? (
              <CloseRegistor
                setRegistorStatus={setRegistorStatus}
                registorId={registorId}
              />
            ) : (
              <OpenRegistor setRegistorStatus={setRegistorStatus} />
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
}
