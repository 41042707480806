import React from "react";
import { Card, CardHeader, CardTitle } from "reactstrap";

export default function QuickTabs(props) {
  const { name, productId, handleSearch } = props;
  return (
    <div
      className="ct-quick-tips-min-width pr-1"
      onClick={() => handleSearch(productId)}
    >
      <Card className="card-user border quick_tab_space">
        <CardHeader>
          <CardTitle
            tag="h6"
            className="text-center"
            style={{
              fontSize: "13px",
              marginTop: "0px",
            }}
          >
            {name}
          </CardTitle>
        </CardHeader>
      </Card>
    </div>
  );
}
