import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactToPrint from "react-to-print";
import { Button, Col, Row, Spinner } from "reactstrap";
import axiosClient from "../../../axios-client";
import PrintReceipt from "./PrintReceipt";
import { Link } from "react-router-dom";

export default function DropDown(props) {
  const { sale, items, getAllSale } = props;
  const [printer, setPrinter] = useState({});
  const componentRef = React.useRef();

  useEffect(() => {
    axiosClient.get("/printer-settings").then(({ data }) => {
      if (data.data.length > 0) {
        setPrinter(data.data[0]);
      }
    });
  }, []);

  const handleReturnSale = () => {
    if (!window.confirm("Are you sure you want to return this sale?")) {
      return;
    }
    axiosClient
      .get(`/return-sale/${sale.id}`)
      .then((data) => {

        getAllSale();
      })
      .catch((data) => {

      });
  };
  return (
    <>
      {items ? (
        <Row className="py-4 my-4">
          <Col lg={10}>
            <div className="border-bottom pb-2">
              <span className="saleHeading pb-2">Sale Details</span>
            </div>
            {items &&
              items.length > 0 &&
              items.map((c, ind) => (
                <Row className="border-bottom py-4" key={ind}>
                  <Col
                    lg={1}
                    style={
                      c.hasOwnProperty("return_status")
                        ? c.return_status === "1"
                          ? {
                              textDecoration: "line-through",
                              color: "red",
                            }
                          : {
                              color: "black",
                            }
                        : {
                            color: "black",
                          }
                    }
                  >
                    {c.item_qty}
                  </Col>
                  <Col
                    lg={4}
                    style={
                      c.hasOwnProperty("return_status")
                        ? c.return_status === "1"
                          ? {
                              textDecoration: "line-through",
                              color: "red",
                            }
                          : {
                              color: "black",
                            }
                        : {
                            color: "black",
                          }
                    }
                  >
                    {c.item_name}
                  </Col>
                  <Col
                    lg={5}
                    style={
                      c.hasOwnProperty("return_status")
                        ? c.return_status === "1"
                          ? {
                              textDecoration: "line-through",
                              color: "red",
                            }
                          : {
                              color: "black",
                            }
                        : {
                            color: "black",
                          }
                    }
                  >
                    ${c.item_price} - $
                    {(parseFloat(c.item_discount).toFixed(2) / 100) *
                      parseFloat(c.item_price).toFixed(2)}{" "}
                    Discount
                  </Col>
                  <Col
                    lg={2}
                    className="text-right"
                    style={
                      c.hasOwnProperty("return_status")
                        ? c.return_status === "1"
                          ? {
                              textDecoration: "line-through",
                              color: "red",
                            }
                          : {
                              color: "black",
                            }
                        : {
                            color: "black",
                          }
                    }
                  >
                    $
                    {parseFloat(c.item_qty).toFixed(2) *
                      (parseFloat(c.item_price).toFixed(2) -
                        (parseFloat(c.item_discount).toFixed(2) / 100) *
                          parseFloat(c.item_price).toFixed(2))}
                  </Col>
                </Row>
              ))}
            <Row className="mt-4">
              <Col lg={4}>
                <h6 className="p-0 m-0">Note</h6>
                <pre
                  className="pt-2"
                  style={{ fontSize: " 14px", fontFamily: "arial" }}
                >
                  {sale.notes}
                </pre>
              </Col>
              <Col lg={8}>
                <Row className="border-bottom py-2">
                  <Col lg={8}>
                    Subtotal
                    <br />
                    Total Tax
                    <br />
                    Total Discount
                  </Col>
                  <Col lg={4} className="text-right">
                    ${sale.sub_total}
                    <br />
                    {sale.tax_type === "0" ? sale.tax + "%" : "$" + sale.tax}
                    <br />
                    {sale.discount_type === "0"
                      ? sale.discount + "%"
                      : "$" + sale.discount}
                  </Col>
                </Row>
                <Row className="border-bottom py-2">
                  <Col lg={8}>
                    <h6 className="p-0 m-0">SALE TOTAL</h6>
                  </Col>
                  <Col lg={4} className="text-right">
                    ${sale.total}
                  </Col>
                </Row>
                <Row className="border-bottom py-2">
                  <Col lg={8}>
                    <h6 className="p-0 m-0">{sale.payment_type}</h6>
                  </Col>
                  <Col lg={4} className="text-right">
                    ${sale.cash_recevied}
                  </Col>
                </Row>
                <Row className="border-bottom py-2">
                  <Col lg={8}>
                    <h6 className="p-0 m-0">Change</h6>
                  </Col>
                  <Col lg={4} className="text-right">
                    ${sale.change}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={2}>
            {sale.return_status === "0" && (
              <>
                <Link
                  to={`/admin/return-items/${sale.id}`}
                  className="btn btn-danger mb-4 w-100"
                >
                  Return Items
                </Link>
                <Button
                  className="btn btn-danger mb-4 w-100"
                  onClick={handleReturnSale}
                >
                  Return
                </Button>
              </>
            )}
            <ReactToPrint
              trigger={() => (
                <Button
                  className="btn btn-primary w-100"
                  onClick={handleReturnSale}
                >
                  Print Receipt
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Col>
        </Row>
      ) : (
        <div className="text-center py-5">
          <Spinner animation="grow" />
        </div>
      )}
      <div style={{ display: "none" }}>
        <PrintReceipt
          ref={componentRef}
          sale={sale}
          items={items}
          printer={printer}
        />
      </div>
    </>
  );
}
