import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, FormGroup, Input } from "reactstrap";

export default function TaxModal(props) {
  const {
    taxModal,
    setTaxModal,
    tax,
    setTax,
    taxType,
    type,
    setType,
    setTaxType,
    discount,
    setDiscount,
    subtotal,
    setSubtotal,
    subtotal2,
    setSubtotal2,
    tStatus,
    setTStatus,
    dValue,
    setDValue,
    tValue,
    setTValue,
  } = props;
  const [dStyle, setDStyle] = useState({});
  useEffect(() => {
    if (taxModal) {
      setDStyle({
        display: "block",
        background: "white",
        padding: "15px 20px",
        top: "65%",
        left: "-45%",
      });
    } else {
      setDStyle({
        display: "none",
        background: "white",
        padding: "15px 20px",
        top: "65%",
        left: "-45%",
      });
    }
  }, [taxModal]);

  useEffect(() => {
    let sTotal = subtotal2;


    if (type === 0) {
      sTotal = sTotal - (sTotal * parseFloat(discount)) / 100;

    } else {
      sTotal = sTotal - parseFloat(discount);

    }

    let sTotal2 = subtotal2;
    if (taxType === 0) {
      sTotal = sTotal + (sTotal2 * parseFloat(tax)) / 100;
    } else {
      sTotal = sTotal + parseFloat(tax);
    }

    setSubtotal(sTotal.toFixed(2));
  }, [subtotal]);

  const handleValue = (ev) => {
    const { value } = ev.target;
    if (value) {
      setTax(value);
      setTValue(value);
    } else {
      setTax(0);
      setTValue(0);
    }
  };
  const handleAddFunction = () => {
    let sTotal = subtotal2;
    if (type === 0) {
      sTotal = sTotal - (sTotal * parseFloat(discount)) / 100;
      setDValue((sTotal * parseFloat(discount)) / 100);
    } else {
      setDValue(parseFloat(discount));
      sTotal = sTotal - parseFloat(discount);
    }
    console.log("console1: ", sTotal);
    let sTotal2 = subtotal2;
    if (taxType === 0) {
      sTotal = sTotal + (sTotal2 * parseFloat(tax)) / 100;
      setTValue((sTotal2 * parseFloat(tax)) / 100);
    } else {
      sTotal = sTotal + parseFloat(tax);
      setTValue(parseFloat(tax));
    }
    setSubtotal(sTotal.toFixed(2));
    setTStatus(true);
    setTaxModal(false);
  };
  return (
    <div style={dStyle} className="border position-absolute">
      <h6 className="border-bottom pb-2">Tax</h6>
      <div style={{ display: "flex" }} className="text-center pt-3">
        <div style={{ width: "20%" }}>
          <h5
            className={taxType === 0 ? "border border-primary" : "border"}
            style={{ cursor: "pointer" }}
            onClick={() => setTaxType(0)}
          >
            %
          </h5>
        </div>
        <div style={{ width: "20%" }}>
          <h5
            className={taxType === 1 ? "border border-primary" : "border"}
            style={{ cursor: "pointer" }}
            onClick={() => setTaxType(1)}
          >
            $
          </h5>
        </div>
        <div style={{ width: "60%" }}>
          <FormGroup>
            <Input
              placeholder="Enter Value"
              type="number"
              className="text-right"
              name="quantity"
              value={tax}
              onChange={handleValue}
            />
          </FormGroup>
        </div>
      </div>
      <div>
        <Button className="btn btn-danger" onClick={() => setTaxModal(false)}>
          Close
        </Button>
        <Button className="btn btn-primary" onClick={handleAddFunction}>
          Add
        </Button>
      </div>
    </div>
  );
}
