import React from 'react'

export default function Alert(props) {
    const {errors} = props;
  return (
    <div>
        {
            Object.keys(errors).map(key=> (
                <div  key={key} className="alert alert-danger alert-dismissible" role="alert">
                        {errors[key][0]}
                </div>
            ))   
        }
        
    </div>
  )
}
