import React, { useEffect, useState } from "react";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../contexts/ContextProvider";
import { IoMdArrowBack } from "react-icons/io";
import AmountPay2 from "./AmountPay2";
import CompleteSale from "./CompleteSale";
export default function AmountPay(props) {
  const {
    sale,
    setView,
    discount,
    type,
    tax,
    taxType,
    subtotal,
    setDStatus,
    setTStatus,
    cashReceived,
    setCashReceived,
    change,
    setChange,
    views,
    setViews,
    handleCompleteSave,
    paymentType,
    setPaymentType,
  } = props;
  const [registorStatus, setRegistorStatus] = useState(null);
  const [total, setTotal] = useState(0);
  const [qty, setQty] = useState(0);
  const { setNotification } = useStateContext();
  useEffect(() => {
    axiosClient
      .get("/check-registor")
      .then(({ data }) => {
        if (data.registor === 0) {
          setRegistorStatus(0);
        } else if (data.registor === 1) {
          setRegistorStatus(1);
        } else {
          setRegistorStatus(2);
        }
      })
      .catch((data) => {});
  }, []);
  useEffect(() => {
    if (sale && sale.length > 0) {
      let Total = 0;
      let Qty = 0;
      sale.forEach((element) => {
        Qty = Qty + parseFloat(element.quantity);
        Total =
          Total + parseFloat(element.price) * parseFloat(element.quantity);
      });
      setQty(Qty);
      setTotal(Total.toFixed(2));
    }
  }, [sale]);
  const handleBack = () => {
    setView(0);
    setViews(false);
    if (discount) {
      setDStatus(true);
    }
    if (tax) {
      setTStatus(true);
    }
  };
  return (
    <Container>
      <Row>
        <Col lg={6}>
          <h2 style={{ cursor: "pointer" }} onClick={handleBack}>
            <IoMdArrowBack />
          </h2>
          {sale &&
            sale.length > 0 &&
            sale.map((c, i) => (
              <Row className="mb-3">
                <Col lg={1} className="font-weight-bold">
                  {c.quantity}
                </Col>
                <Col lg={8} className="font-weight-bold">
                  <div>{c.name}</div>
                  {c.notes && <div>Note: {c.notes}</div>}
                </Col>
                <Col lg={3} className="font-weight-bold text-right">
                  {c.discount ? (
                    <>
                      <div>
                        $
                        {parseFloat(c.quantity).toFixed(2) *
                          parseFloat(c.price).toFixed(2) -
                          parseFloat(c.quantity).toFixed(2) *
                            parseFloat(c.price).toFixed(2) *
                            (c.discount / 100).toFixed(2)}
                      </div>
                      <strike>
                        {"$" +
                          parseFloat(c.quantity).toFixed(2) *
                            parseFloat(c.price).toFixed(2)}
                      </strike>
                    </>
                  ) : (
                    "$" +
                    parseFloat(c.quantity).toFixed(2) *
                      parseFloat(c.price).toFixed(2)
                  )}
                </Col>
              </Row>
            ))}
          <hr />
          <Row>
            <Col lg={4} className="font-weight-bold text-right">
              Subtotal
            </Col>
            <Col lg={8} className="font-weight-bold text-right">
              ${total}
            </Col>
          </Row>
          {discount != 0 && discount && (
            <Row>
              <Col lg={4} className="font-weight-bold text-right">
                Additional Discount
              </Col>
              <Col lg={8} className="font-weight-bold text-right">
                {type === 0 ? `${discount}%` : `$${discount}`}
              </Col>
            </Row>
          )}
          {tax != 0 && tax && (
            <Row>
              <Col lg={4} className="font-weight-bold text-right">
                Tax No Tax
              </Col>
              <Col lg={8} className="font-weight-bold text-right">
                {taxType === 0 ? `${tax}%` : `$${tax}`}
              </Col>
            </Row>
          )}
          <hr />
          <Row>
            <Col lg={6} className="font-weight-bold text-left">
              <h3 className="m-0 p-0">
                Sale Total{" "}
                <span className="ml-1" style={{ fontSize: "15px" }}>
                  {qty} items
                </span>
              </h3>
            </Col>
            <Col lg={6} className="font-weight-bold text-right">
              <h3 className="m-0 p-0">${subtotal}</h3>
            </Col>
          </Row>
          <hr />
          {views && (
            <>
              <Row>
                <Col lg={4} className="font-weight-bold text-right">
                  Cash
                </Col>
                <Col lg={8} className="font-weight-bold text-right">
                  ${cashReceived}
                </Col>
              </Row>
              <Row>
                <Col lg={4} className="font-weight-bold text-right">
                  <h3 className="m-0 p-0">Change</h3>
                </Col>
                <Col lg={8} className="font-weight-bold text-right">
                  <h3 className="m-0 p-0">${change}</h3>
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col lg={6}>
          {views ? (
            <CompleteSale
              cashReceived={cashReceived}
              setCashReceived={setCashReceived}
              views={views}
              setViews={setViews}
              subtotal={subtotal}
              change={change}
              setChange={setChange}
              handleCompleteSave={handleCompleteSave}
            />
          ) : (
            <AmountPay2
              subtotal={subtotal}
              cashReceived={cashReceived}
              setCashReceived={setCashReceived}
              views={views}
              setViews={setViews}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
