/*!

=========================================================
* Paper Dashboard React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/Dashboard.js";
import Notifications from "./views/Notifications.js";
import Icons from "./views/Icons.js";
import Typography from "./views/Typography.js";
import TableList from "./views/Tables.js";
import Maps from "./views/Map.js";
import UserPage from "./views/User.js";
import UpgradeToPro from "./views/Upgrade.js";
import Login from "./views/Login";
import CategoryList from "./views/Category/CategoryList";
import CategoryForm from "./views/Category/CategoryForm";
import ProductList from "./views/Products/ProductList";
import ProductForm from "./views/Products/ProductForm";
import Sale from "./views/Sale/Sale";
import Registor from "./views/Registor/Registor.jsx";
import QuickTabForm from "./views/QuickTab/QuickTabForm.jsx";
import QuickTabList from "./views/QuickTab/QuickTabList.jsx";
import SaleHistory from "./views/SaleHistory/SaleHistory.jsx";
import PrinterSettings from "./views/PrinterSetting/PrinterSettings.jsx";
import SaleReturn from "./views/SaleReturn/SaleReturn.jsx";
import RegistorHistory from "./views/RegistorHistory/RegistorHistory.jsx";
import ViewRegistorHistory from "./views/RegistorHistory/Components/ViewRegistorHistory.jsx";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/sale",
    name: "Sale",
    icon: "nc-icon nc-bag-16",
    component: Sale,
    layout: "/admin",
  },
  {
    path: "/history-sale",
    name: "Sale History",
    icon: "nc-icon nc-tag-content",
    component: SaleHistory,
    layout: "/admin",
  },
  {
    path: "/registor",
    name: "Open/Close",
    icon: "nc-icon nc-book-bookmark",
    component: Registor,
    layout: "/admin",
  },
  {
    path: "/history-registor/:id",
    name: "Registory Details",
    icon: "sub-cat",
    component: ViewRegistorHistory,
    layout: "/admin",
  },
  {
    path: "/history-registor",
    name: "Registory History",
    icon: "nc-icon nc-single-copy-04",
    component: RegistorHistory,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-diamond",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-single-02",
  //   component: UserPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography,
  //   layout: "/admin",
  // },
  {
    path: "/category/new",
    name: "Category New",
    icon: "sub-cat",
    component: CategoryForm,
    layout: "/admin",
  },
  {
    path: "/category/edit/:id",
    name: "Category Edit",
    icon: "sub-cat",
    component: CategoryForm,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Categories",
    icon: "nc-icon nc-layout-11",
    component: CategoryList,
    layout: "/admin",
  },
  {
    path: "/product/new",
    name: "Product New",
    icon: "sub-cat",
    component: ProductForm,
    layout: "/admin",
  },
  {
    path: "/product/edit/:id",
    name: "Product Edit",
    icon: "sub-cat",
    component: ProductForm,
    layout: "/admin",
  },
  {
    path: "/return-items/:id",
    name: "Sale Edit",
    icon: "sub-cat",
    component: SaleReturn,
    layout: "/admin",
  },
  {
    path: "/product",
    name: "Products",
    icon: "nc-icon nc-app",
    component: ProductList,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-caps-small",
    component: Login,
    layout: "/guest",
  },
  {
    path: "/quicktab/new",
    name: "QuickTab New",
    icon: "sub-cat",
    component: QuickTabForm,
    layout: "/admin",
  },
  {
    path: "/quicktab/edit/:id",
    name: "QuickTab Edit",
    icon: "sub-cat",
    component: QuickTabForm,
    layout: "/admin",
  },
  {
    path: "/quicktab",
    name: "Quick Tabs",
    icon: "nc-icon nc-bullet-list-67",
    component: QuickTabList,
    layout: "/admin",
  },
  {
    path: "/printerSettings",
    name: "Printer Settings",
    icon: "nc-icon nc-paper",
    component: PrinterSettings,
    layout: "/admin",
  },
];

export default routes;
