import React from 'react';
import { Route, Switch, useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import routes from '../routes';
import { useStateContext } from '../contexts/ContextProvider';

export default function Guest(props) {
    const {token, notification} = useStateContext();
    const history = useHistory();
    console.log(notification);
    if( notification ){
        console.log(notification);
        if(notification[0] === 200 ){
          toast.success(notification[1], {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
        else{
          toast.error(notification[1], {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      }
    if(token){
        history.push("/admin/dashboard");
    }
    
    return (
    <div>
      <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>
        <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
    </div>
  )
}
