import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { RxCross1 } from "react-icons/rx";

export default function ItemModal(props) {
  const { items, mIndex, itemModal, setItemModal } = props;
  const handleCloseModal = () => {
    const list = [...itemModal];
    list[mIndex] = false;
    setItemModal(list);
  };
  return (
    <Card
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        zIndex: "9999",
      }}
    >
      <CardHeader>
        <Row>
          <Col lg={8}>
            <CardTitle tag="h6">Item Details</CardTitle>
          </Col>
          <Col lg={4} className="text-right">
            <h5 className="m-0 p-0">
              <RxCross1
                style={{ cursor: "pointer" }}
                onClick={handleCloseModal}
              />
            </h5>
          </Col>
        </Row>
        <CardBody>
          <Table className="table-hover" responsive>
            <thead className="text-primary">
              <tr>
                <th>Sr#</th>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Note</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.length > 0 &&
                items.map((con, ind) => (
                  <tr>
                    <td>{ind+1}</td>
                    <td>{con.item_name}</td>
                    <td>{con.item_qty}</td>
                    <td>{con.item_price}</td>
                    <td>{con.item_discount}</td>
                    <td>{con.item_note}</td>
                    <td>
                      {con.return_status === "0" ? "Regular" : "Returned"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </CardHeader>
    </Card>
  );
}
