import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import AllSale from "./Components/AllSale";
import CompleteSale from "./Components/CompleteSale";
import ReturnSale from "./Components/ReturnSale";
import { useEffect } from "react";
import axiosClient from "../../axios-client";
import LoadingBar from "react-top-loading-bar";

export default function SaleHistory() {
  const [activeTab, setActiveTab] = useState("1");
  const [dropDown, setDropDown] = useState([]);
  const [allSale, setAllSale] = useState([]);
  const [links, setLinks] = useState({
    first: null,
    last: null,
    next: null,
    prev: null,
  });
  const [metaData, setMetaData] = useState({
    current_page: null,
    from: null,
    last_page: null,
    plinks: null,
    path: null,
    per_page: null,
    to: null,
    total: null,
  });
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    getAllSale();
  }, []);

  const getAllSale = () => {
    setProgress(25);
    axiosClient
      .get("/all-sale")
      .then(({ data }) => {

        setAllSale(data.data);
        if (data.data.length > 0) {
          let ary = [];
          data.data.forEach((element) => {
            ary.push(false);
          });
          setDropDown(ary);
        }
        setProgress(100);
        setLinks({
          ...links,
          next: data.next_page_url,
          prev: data.prev_page_url,
          first: data.first_page_url,
          last: data.last_page_url,
        });
        setMetaData({
          ...metaData,
          current_page: data.current_page,
          from: data.from,
          last_page: data.last_page,
          plinks: data.links,
          path: data.path,
          per_page: data.per_page,
          to: data.to,
          total: data.total,
        });
      })
      .catch(() => {
        setProgress(100);
      });
  };

  const handleTabs = async (type) => {
    if (type === "1") {
      setProgress(25);
      await axiosClient
        .get("/all-sale")
        .then(({ data }) => {
          console.log(data);
          setAllSale(data.data);
          if (data.data.length > 0) {
            let ary = [];
            data.data.forEach((element) => {
              ary.push(false);
            });
            setDropDown(ary);
          }
          setProgress(100);
          setLinks({
            ...links,
            next: data.next_page_url,
            prev: data.prev_page_url,
            first: data.first_page_url,
            last: data.last_page_url,
          });
          setMetaData({
            ...metaData,
            current_page: data.current_page,
            from: data.from,
            last_page: data.last_page,
            plinks: data.links,
            path: data.path,
            per_page: data.per_page,
            to: data.to,
            total: data.total,
          });
        })
        .catch(() => {
          setProgress(100);
        });
      setActiveTab("1");
    } else if (type === "2") {
      setProgress(25);
      await axiosClient
        .get("/complete-sale-list")
        .then(({ data }) => {

          setAllSale(data.data);
          if (data.data.length > 0) {
            let ary = [];
            data.data.forEach((element) => {
              ary.push(false);
            });
            setDropDown(ary);
          }
          setProgress(100);
          setLinks({
            ...links,
            next: data.next_page_url,
            prev: data.prev_page_url,
            first: data.first_page_url,
            last: data.last_page_url,
          });
          setMetaData({
            ...metaData,
            current_page: data.current_page,
            from: data.from,
            last_page: data.last_page,
            plinks: data.links,
            path: data.path,
            per_page: data.per_page,
            to: data.to,
            total: data.total,
          });
        })
        .catch(() => {
          setProgress(100);
        });
      setActiveTab("2");
    } else {
      setProgress(25);
      await axiosClient
        .get("/return-sale-list")
        .then(({ data }) => {

          setAllSale(data.data);
          if (data.data.length > 0) {
            let ary = [];
            data.data.forEach((element) => {
              ary.push(false);
            });
            setDropDown(ary);
          }
          setProgress(100);
          setLinks({
            ...links,
            next: data.next_page_url,
            prev: data.prev_page_url,
            first: data.first_page_url,
            last: data.last_page_url,
          });
          setMetaData({
            ...metaData,
            current_page: data.current_page,
            from: data.from,
            last_page: data.last_page,
            plinks: data.links,
            path: data.path,
            per_page: data.per_page,
            to: data.to,
            total: data.total,
          });
        })
        .catch(() => {
          setProgress(100);
        });
      setActiveTab("3");
    }
  };
  return (
    <div className="content">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Sale History</CardTitle>
        </CardHeader>
        <CardBody>
          <Nav tabs>
            <NavItem
              className={activeTab === "1" ? "btn btn-primary active" : "btn"}
              onClick={() => handleTabs("1")}
            >
              All
            </NavItem>
            <NavItem
              className={activeTab === "2" ? "btn btn-primary active" : "btn"}
              onClick={() => handleTabs("2")}
            >
              Completed
            </NavItem>
            <NavItem
              className={activeTab === "3" ? "btn btn-primary active" : "btn"}
              onClick={() => handleTabs("3")}
            >
              Return
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <AllSale
                metaData={metaData}
                dropDown={dropDown}
                allSale={allSale}
                setDropDown={setDropDown}
                setProgress={setProgress}
                setMetaData={setMetaData}
                links={links}
                setLinks={setLinks}
                setAllSale={setAllSale}
                getAllSale={getAllSale}
              />
            </TabPane>
            <TabPane tabId="2">
              <CompleteSale
                metaData={metaData}
                dropDown={dropDown}
                allSale={allSale}
                setDropDown={setDropDown}
                setProgress={setProgress}
                setMetaData={setMetaData}
                links={links}
                setLinks={setLinks}
                setAllSale={setAllSale}
                getAllSale={getAllSale}
              />
            </TabPane>
            <TabPane tabId="3">
              <ReturnSale
                metaData={metaData}
                dropDown={dropDown}
                allSale={allSale}
                setDropDown={setDropDown}
                setProgress={setProgress}
                setMetaData={setMetaData}
                links={links}
                setLinks={setLinks}
                setAllSale={setAllSale}
                getAllSale={getAllSale}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}
