import React, { useState } from 'react'
import {
    Container,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col
  } from "reactstrap";
import background from '../assets/img/background.jpg';
import LoadingBar from 'react-top-loading-bar';
import Alert from './Alert';
import { useHistory } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import axiosClient from '../axios-client';
export default function Login() {
    const [progress, setProgress] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(null);
    const {setToken, setNotification} = useStateContext();
    const history = useHistory();
    const onSubmit = (ev) => {
        ev.preventDefault();
        setProgress(25);
        const payload = {
            email: email,
            password: password
        };
        setErrors(null);
        axiosClient.post('/login', payload)
        .then(({data})=>{
            setToken(data.token);
            setNotification(200, "Account Login Successfully");

            history.push("/admin/dashboard");
        })
        .catch(err => {
            const response = err.response;
            if(response && response.status === 422){
              if(response.data.errors){
                setErrors( response.data.errors );
                setNotification(400, "Some Error Occur");
              }
              else{
                setErrors( {
                  email: [response.data.message]
                } );
                setNotification(400, "Some Error Occur");
              }
                
            }
        })
        setProgress(100);
    }
  return (
    <>
        <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <div style={{background: `url('${background}')`}}>
        <Container>
            <Row className='align-items-center'>
                <Col lg={3} style={{height: '100vh'}}>
                </Col>
                <Col lg={6}>
                <Card className="card-user border">
                    <CardHeader className='text-center'>
                        <CardTitle tag="h5">Welcome to Cell Times</CardTitle>
                        {
                            errors && 
                            <div>
                            {
                                <Alert errors={errors} />
                            }
                            </div>
                        }
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={onSubmit}>
                        <Row>
                            <Col className="px-4" md="12">
                            <FormGroup>
                                <label>Email</label>
                                <Input
                                placeholder="Enter Email"
                                type="email"
                                onChange={ ev => setEmail( ev.target.value ) }
                                value={email}
                                />
                            </FormGroup>
                            </Col>
                            <Col className="px-4" md="12">
                            <FormGroup>
                                <label>Password</label>
                                <Input
                                placeholder="******"
                                type="password"
                                onChange={ ev => setPassword( ev.target.value ) }
                                value={password}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <div className="update ml-auto mr-auto">
                            <Button
                                className="btn-round"
                                color="primary"
                                type="submit"
                            >
                                Login
                            </Button>
                            </div>
                        </Row>
                        </Form>
                    </CardBody>
                    </Card>
                </Col>
                <Col lg={3}>
                </Col>
            </Row>
        </Container>
        </div>
    </>
  )
}
