import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import LoadingBar from 'react-top-loading-bar'
import { useHistory, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../Alert';


export default function CategoryForm() {
    const {id} = useParams();
    const { setNotification } = useStateContext();
    const [category, setCategory] = useState({
        id: null,
        name: '',
    });
    const [progress, setProgress] = useState(0);
    const [errors, setErrors] = useState(null);
    const hisotry = useHistory();


    useEffect(()=>{
        if(id){
            setProgress(25);
            axiosClient.get(`/category/${id}`)
            .then(({data})=>{
                setProgress(100);
                setCategory(data);
            })
            .catch(()=>{
                setProgress(100);
            })
        }
    }, [])


    const SubmitForm = (ev) => {
        ev.preventDefault();
        setProgress(25)
        setErrors(null)
        if(id){
            axiosClient.put(`/category/${category.id}`, category)
            .then(()=>{
                setProgress(100)
                setNotification(200,'Category was successfully updated');
                hisotry.push('/admin/category');
            })
            .catch(err => {
                const response = err.response;
                if(response && response.status === 422){
                    setErrors( response.data.errors );    
                    setProgress(100)                
                }
            })
        }
        else{
            axiosClient.post(`/category`, category)
            .then(()=>{
                setProgress(100)
                setNotification(200,'Category was successfully created');
                hisotry.push('/admin/category');
            })
            .catch(err => {
                const response = err.response;
                if(response && response.status === 422){
                    setProgress(100)
                    setErrors( response.data.errors );      
                    setNotification(400,'Some Error Occurs');              
                }
            })
        }
    }
  return (
    <div className='content'>
    <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
      <Row>
        <Col lg={12}>
            <Card className="card-user">
                <CardHeader>
                <CardTitle tag="h5">Category Form</CardTitle>
                {
                    errors && 
                    <div className='px-3'>
                    <Alert errors={errors} />
                    </div>
                }
                </CardHeader>
                <CardBody>
                <Form onSubmit={SubmitForm}>
                    <Row>
                    <Col md="12">
                        <FormGroup>
                        <label>Category Name</label>
                        <Input
                            placeholder="Enter Category Name"
                            type="text"
                            onChange={ev=>setCategory({...category, name: ev.target.value})}
                            value={category.name}
                        />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={12}>
                        <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        >
                        { id ? 'Update': 'Save'}
                        </Button>
                    </Col>
                    </Row>
                </Form>
                </CardBody>
            </Card>
        </Col>
      </Row>
    </div>
  )
}
