import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import LoadingBar from "react-top-loading-bar";
import { useHistory, useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";
import Alert from "../Alert";

export default function QuickTabForm() {
  const { id } = useParams();
  const { setNotification } = useStateContext();
  const [quicktab, setQuicktab] = useState({
    id: null,
    name: "",
    product_id: "",
  });
  const [progress, setProgress] = useState(0);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState(null);
  const hisotry = useHistory();

  useEffect(() => {
    axiosClient.get("/product-list").then(({ data }) => {
      setProducts(data.data);
    });
  }, []);
  useEffect(() => {
    if (id) {
      setProgress(25);
      axiosClient
        .get(`/quick-tabs/${id}`)
        .then(({ data }) => {
          setProgress(100);
          setQuicktab(data.data);
        })
        .catch(() => {
          setProgress(100);
        });
    }
  }, []);

  const SubmitForm = (ev) => {
    ev.preventDefault();
    setProgress(25);
    setErrors(null);
    if (id) {
      axiosClient
        .put(`/quick-tabs/${quicktab.id}`, quicktab)
        .then(() => {
          setProgress(100);
          setNotification(200, "Quick Tab was successfully updated");
          hisotry.push("/admin/quicktab");
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
            setProgress(100);
          }
        });
    } else {
      axiosClient
        .post(`/quick-tabs`, quicktab)
        .then(() => {
          setProgress(100);
          setNotification(200, "Quick Tab was successfully created");
          hisotry.push("/admin/quicktab");
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setProgress(100);
            setErrors(response.data.errors);
            setNotification(400, "Some Error Occurs");
          }
        });
    }
  };
  const handleProudctSelect = (ev) => {
    const select = ev.target;
    const selectedOption = select.options[select.selectedIndex];
    if (ev.target.value === "--select--") {
      setQuicktab({
        ...quicktab,
        product_id: "",
        name: "",
      });
    } else {
      setQuicktab({
        ...quicktab,
        product_id: ev.target.value,
        name: selectedOption.innerText,
      });
    }
  };
  return (
    <div className="content">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Row>
        <Col lg={12}>
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">Quick Tab Form</CardTitle>
              {errors && (
                <div className="px-3">
                  <Alert errors={errors} />
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Form onSubmit={SubmitForm}>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Quick Tab Name</label>
                      <Input
                        placeholder="Enter Quick Tab Name"
                        type="text"
                        onChange={(ev) =>
                          setQuicktab({ ...quicktab, name: ev.target.value })
                        }
                        value={quicktab.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label>Product</label>
                      <select
                        className="form-control"
                        value={quicktab.product_id}
                        onChange={handleProudctSelect}
                      >
                        <option>--select--</option>
                        {products.length > 0 &&
                          products.map((c, i) => (
                            <option value={c.id} key={i}>
                              {c.name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Button className="btn-round" color="primary" type="submit">
                      {id ? "Update" : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
