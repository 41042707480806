import { createContext, useContext, useState } from "react";

const StateContext = createContext({
    user: null,
    token: null,
    notification: null,
    setUser: () => {},
    setToken: () => {},
    setNotification: () => {}
});

export const ContextProvider = ({children}) => {
    const [user, setUser] = useState({ });
    const [notification, _setNotfication] = useState('');
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN_CELL_TIMES'));

    const setNotification = (st, message) => {
        console.log(message);
        _setNotfication([st, message]);
        setTimeout(() => {
            _setNotfication('');
        }, 100);
    }

    const setToken = (token) => {
        _setToken(token);
        if(token){
            localStorage.setItem('ACCESS_TOKEN_CELL_TIMES', token);
        }
        else{
            localStorage.removeItem('ACCESS_TOKEN_CELL_TIMES');
        }
    }
    return (
        <StateContext.Provider value={{
            user,
            token,
            setUser,
            setToken,
            notification,
            setNotification
        }}>
        {children}
        </StateContext.Provider>
    );
}

export const useStateContext = () => useContext(StateContext);