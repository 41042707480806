import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import LoadingBar from "react-top-loading-bar";
import axiosClient from "../../axios-client";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../Pagination";

export default function RegistorHistory() {
  const [progress, setProgress] = useState(0);
  const [links, setLinks] = useState({
    first: null,
    last: null,
    next: null,
    prev: null,
  });
  const [metaData, setMetaData] = useState({
    current_page: null,
    from: null,
    last_page: null,
    plinks: null,
    path: null,
    per_page: null,
    to: null,
    total: null,
  });
  const [content, setContent] = useState({});
  useEffect(() => {
    setProgress(25);
    axiosClient.get("/registors").then(({ data }) => {
      console.log(data);
      setContent(data.data);
      setLinks({
        ...links,
        next: data.next_page_url,
        prev: data.prev_page_url,
        first: data.first_page_url,
        last: data.last_page_url,
      });
      setMetaData({
        ...metaData,
        current_page: data.current_page,
        from: data.from,
        last_page: data.last_page,
        plinks: data.links,
        path: data.path,
        per_page: data.per_page,
        to: data.to,
        total: data.total,
      });
      setProgress(100);
    });
  }, []);

  const handlePagnate = (type, url = null) => {
    if (type === "prev" && links.prev) {
      paginateData(links.prev);
    } else if (type === "next" && links.next) {
      paginateData(links.next);
    } else if (type === "first" && links.prev) {
      paginateData(links.first);
    } else if (type === "last" && links.next) {
      paginateData(links.last);
    } else if (type === "single") {
      paginateData(url);
    }
  };
  const paginateData = (aLink) => {
    setProgress(25);
    axiosClient.get(aLink).then(({ data }) => {
      setContent(data.data);
      setLinks({
        ...links,
        next: data.next_page_url,
        prev: data.prev_page_url,
        first: data.first_page_url,
        last: data.last_page_url,
      });
      setMetaData({
        ...metaData,
        current_page: data.current_page,
        from: data.from,
        last_page: data.last_page,
        plinks: data.links,
        path: data.path,
        per_page: data.per_page,
        to: data.to,
        total: data.total,
      });
      setProgress(100);
    });
  };
  return (
    <div className="content">
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Registor History</CardTitle>
        </CardHeader>
        <CardBody>
          <Table className="table-hover" responsive>
            <thead className="text-primary">
              <tr>
                <th>Clouser#</th>
                <th>Date</th>
                <th>Open Cash($)</th>
                <th>Close Cash($)</th>
                <th>Expected Cash($)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {content &&
                content.length > 0 &&
                content.map((con, index) => (
                  <tr key={index}>
                    <td>CTA-{con.id}</td>
                    <td>
                      {moment(con.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                    </td>
                    <td>{con.open_float_cash}</td>
                    <td>{con.close_float_cash}</td>
                    <td>{con.expected_cash}</td>
                    <td>
                      <Link
                        to={`/admin/history-registor/${con.id}`}
                        className="btn btn-primary btn-round w-100"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pagination metaData={metaData} handlePagnate={handlePagnate} />
        </CardBody>
      </Card>
    </div>
  );
}
