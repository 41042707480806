import React from "react";
import { Table } from "reactstrap";
import axiosClient from "../../../axios-client";
import Pagination from "../../Pagination";

import DropDown from "./DropDown";
import moment from "moment/moment";

export default function ReturnSale(props) {
  const {
    metaData,
    dropDown,
    allSale,
    setDropDown,
    setProgress,
    setMetaData,
    links,
    setLinks,
    setAllSale,
    getAllSale,
  } = props;
  const handlePagnate = (type, url = null) => {
    if (type === "prev" && links.prev) {
      paginateData(links.prev);
    } else if (type === "next" && links.next) {
      paginateData(links.next);
    } else if (type === "first" && links.prev) {
      paginateData(links.first);
    } else if (type === "last" && links.next) {
      paginateData(links.last);
    } else if (type === "single") {
      paginateData(url);
    }
  };
  const paginateData = (aLink) => {
    setProgress(25);
    axiosClient.get(aLink).then(({ data }) => {
      setAllSale(data.data);
      setLinks({
        ...links,
        next: data.next_page_url,
        prev: data.prev_page_url,
        first: data.first_page_url,
        last: data.last_page_url,
      });
      setMetaData({
        ...metaData,
        current_page: data.current_page,
        from: data.from,
        last_page: data.last_page,
        plinks: data.links,
        path: data.path,
        per_page: data.per_page,
        to: data.to,
        total: data.total,
      });
      setProgress(100);
    });
  };

  const handleOpenMore = (ev, ind) => {
    let list = [...dropDown];
    if (list.length > 0) {
      list.forEach((element, index) => {
        if (index === ind) {

          if (list[index]) {
            list[index] = false;
          } else {
            list[index] = true;
          }
        } else {
          list[index] = false;
        }
      });
    }
    setDropDown(list);
  };

  return (
    <div>
      <Table className="table-hover" responsive>
        <thead className="text-primary">
          <tr>
            <th>Receipt</th>
            <th>Date</th>
            <th>Note</th>
            <th>Sale Total</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allSale === "" ? (
            <>
              <tr>
                <th colSpan={3}>
                  <h3 className="text-center">
                    Please enter category to display list
                  </h3>
                </th>
              </tr>
            </>
          ) : (
            allSale.map((u, index) => (
              <>
                <tr
                  key={u.sale_num}
                  onClick={(ev) => handleOpenMore(ev, index)}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    <strong>CTA-{u.sale_num}</strong>
                  </td>
                  <td>{moment(u.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                  <td>{u.notes}</td>
                  <td className="font-weight-bold">${u.total}</td>
                  <td>{u.return_status === "0" ? "Completed" : "Return"}</td>
                </tr>
                {dropDown.length > 0 && dropDown[index] && (
                  <tr>
                    <td colSpan={4}>
                      <DropDown sale={u} items={u.sale_items} getAllSale={getAllSale} />
                    </td>
                  </tr>
                )}
              </>
            ))
          )}
        </tbody>
      </Table>
      <Pagination metaData={metaData} handlePagnate={handlePagnate} />
    </div>
  );
}
