import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, FormGroup, Input } from "reactstrap";

export default function DiscountModal(props) {
  const {
    discountModal,
    setDiscountModal,
    discount,
    setDiscount,
    type,
    setType,
    subtotal,
    setSubtotal,
    taxType,
    subtotal2,
    setDStatus,
    tax,
    setTax,
    dValue,
    setDValue,
    tValue,
    setTValue,
  } = props;
  const [dStyle, setDStyle] = useState({});
  useEffect(() => {
    if (discountModal) {
      setDStyle({
        display: "block",
        background: "white",
        padding: "15px 20px",
        top: "65%",
        left: "-45%",
      });
    } else {
      setDStyle({
        display: "none",
        background: "white",
        padding: "15px 20px",
        top: "65%",
        left: "-45%",
      });
    }
  }, [discountModal]);

  const handleValue = (ev) => {
    const { value } = ev.target;
    if (value) {
      setDiscount(value);
      setDValue(value);
    } else {
      setDiscount(0);
      setDValue(0);
    }
  };
  useEffect(() => {
    let sTotal = subtotal2;
    if (type === 0) {
      sTotal = sTotal - dValue;
    } else {
      sTotal = sTotal - dValue;
    }

    let sTotal2 = subtotal2;
    if (taxType === 0) {
      sTotal = sTotal + tValue;
    } else {
      sTotal = sTotal + tValue;
    }

    setSubtotal(sTotal.toFixed(2));
  }, [subtotal]);

  const handleAddFunction = () => {
    let sTotal = subtotal2;
    if (type === 0) {
      sTotal = sTotal - (sTotal * parseFloat(discount)) / 100;
      setDValue((sTotal * parseFloat(discount)) / 100);
    } else {
      setDValue(parseFloat(discount));
      sTotal = sTotal - parseFloat(discount);
    }

    let sTotal2 = subtotal2;
    if (taxType === 0) {
      sTotal = sTotal + (sTotal2 * parseFloat(tax)) / 100;
      setTValue((sTotal2 * parseFloat(tax)) / 100);
    } else {
      sTotal = sTotal + parseFloat(tax);
      setTValue(parseFloat(tax));
    }
    setSubtotal(sTotal.toFixed(2));
    setDStatus(true);
    setDiscountModal(false);
  };
  return (
    <div style={dStyle} className="border position-absolute">
      <h6 className="border-bottom pb-2">Discount</h6>
      <div style={{ display: "flex" }} className="text-center pt-3">
        <div style={{ width: "20%" }}>
          <h5
            className={type === 0 ? "border border-primary" : "border"}
            style={{ cursor: "pointer" }}
            onClick={() => setType(0)}
          >
            %
          </h5>
        </div>
        <div style={{ width: "20%" }}>
          <h5
            className={type === 1 ? "border border-primary" : "border"}
            style={{ cursor: "pointer" }}
            onClick={() => setType(1)}
          >
            $
          </h5>
        </div>
        <div style={{ width: "60%" }}>
          <FormGroup>
            <Input
              placeholder="Enter Value"
              type="number"
              className="text-right"
              name="quantity"
              value={discount}
              onChange={handleValue}
            />
          </FormGroup>
        </div>
      </div>
      <div>
        <Button
          className="btn btn-danger"
          onClick={() => setDiscountModal(false)}
        >
          Close
        </Button>
        <Button className="btn btn-primary" onClick={handleAddFunction}>
          Add
        </Button>
      </div>
    </div>
  );
}
